import {
  Box,
  Checkbox,
  Chip,
  CircularProgress,
  Fab,
  FormControlLabel,
  Grid,
  IconButton,
  Paper,
  TablePagination,
  TextField,
  Typography,
} from '@material-ui/core'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import Layout from '../../../components/Layout'
import ArrowBackIcon from '@material-ui/icons/ArrowBackIos'
import PlayArrowIcon from '@material-ui/icons/PlayArrow'
import StopIcon from '@material-ui/icons/Stop'
import AnimationView from '../../../components/AnimationView'
import { blue } from '@material-ui/core/colors'
import { Save as SaveIcon } from '@material-ui/icons'
import { v4 as uuidv4 } from 'uuid'
import InputBase from '@material-ui/core/InputBase'
import SearchIcon from '@material-ui/icons/Search'
import {
  getCollectionReference,
  getDocumentReference,
  getSignsBatch,
  IAnimation,
  IManuallyTranslation,
  IManuallyTranslationErrors,
  IManuallyTranslationSentence,
  IManuallyTranslationTarget,
  IPaginationConfig,
  ISign,
  updateManuallyTranslation,
  getInBatch,
  ILegacyPD,
  runBatchOperationsFirestore,
  IBatchOperation,
  IUserInfo,
  preProcess,
  parseFingerspell,
} from 'collections'
import {
  collection,
  documentId,
  getDoc,
  getDocs,
  query,
  orderBy,
  DocumentData,
  Query,
  limit,
  where,
  startAfter,
  endBefore,
  limitToLast,
} from '@firebase/firestore'
import useNmtSuggestion from '../../../hooks/useNmtSuggestion'
import ConfirmationDialog from '../../../components/ConfirmationDialog'
import { useLocation, useRoute } from 'wouter'
import { routes } from '../../../community'
import SearchSign from './SearchSignList'
import { useFsUserDocData, useFsUserDocRef } from '../../../hooks/useFsUser'
import useSnackbar from '../../../services/hooks/useSnackbar'
import { cmsTypesName } from '../../../services/utils'
import useIntl from '../../../hooks/useIntl'
import { useFirestore, useFirestoreCollection } from 'reactfire'
import {
  DndContext,
  DragEndEvent,
  DragOverEvent,
  DragOverlay,
  DragStartEvent,
  PointerSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core'
import { DroppableAccordionList } from './DroppableAccordionList'
import { cloneDeep } from 'lodash-es'
import { changeCMSStatus, finalizeCMSProject } from '../../../services/cmsRequests'
import useGetNmtSign from '../../../hooks/useGetNmtSign'
import Tooltip from '@material-ui/core/Tooltip'
import { DocumentReference, Timestamp } from 'firebase/firestore'
import ThumbDownAltIcon from '@material-ui/icons/ThumbDownAlt'
import ThumbUpAltIcon from '@material-ui/icons/ThumbUpAlt'
import EditIcon from '@material-ui/icons/Edit'
import { useRef } from 'react'
import { feedbackFunction, getUsersByWorkspaceFunction } from '../../../services/firebase'
import styled from 'styled-components'
import ReportModal from '../../ValidateTranslations/ReportModal'
import deepEqual from 'deep-equal'
import { SignWarningDialog } from '../../../components/SignWarningDialog'

const StyledLoaderWrapper = styled.div`
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
`

const StyledLoader = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
`

interface ITranslateProps {
  path?: string
}

interface ISearchFieldSignProps {
  textSearch: string
  onKeyUpSearch: (e: React.KeyboardEvent<HTMLInputElement>) => void
  onTextChange: (event: React.ChangeEvent<HTMLInputElement>) => void
  text: string
  onClickSearch: () => void
  onChangeUseNmtCheck: (checked: boolean) => void
  nmtSearch: boolean
}
const SearchFieldSign = ({
  text,
  onTextChange,
  onKeyUpSearch,
  textSearch,
  onClickSearch,
  onChangeUseNmtCheck,
  nmtSearch,
}: ISearchFieldSignProps) => {
  const intl = useIntl()
  return (
    <Paper variant="outlined">
      <Box display="flex" alignItems="center" borderBottom="1px solid #cecece" borderRadius={0}>
        <InputBase
          placeholder={textSearch}
          style={{ padding: 10, width: '100%' }}
          value={text}
          onChange={onTextChange}
          onKeyUp={onKeyUpSearch}
        />
        <FormControlLabel
          control={
            <Checkbox
              size="small"
              checked={nmtSearch}
              onChange={(_ev, checked) => {
                onChangeUseNmtCheck(checked)
              }}
              style={{ padding: '5px', color: blue[500] }}
            />
          }
          label={<Typography variant="body2">NMT</Typography>}
        />
        <Tooltip title={intl.get('messages.search')}>
          <IconButton
            aria-label="search"
            style={{ padding: 10 }}
            onClick={() => {
              onClickSearch()
            }}
          >
            <SearchIcon />
          </IconButton>
        </Tooltip>
      </Box>
    </Paper>
  )
}

let intervalRef: NodeJS.Timeout | null

const initialPaginationConfig: IPaginationConfig = {
  currentPage: 0,
  firstOfList: null,
  lastOfList: null,
  load: false,
  rowsPerPage: 10,
}

const initialTranslationsPaginationConfig = {
  currentPage: 0,
  firstOfList: 0,
  lastOfList: 10,
  load: false,
  rowsPerPage: 10,
  pagination: {
    paginationModel: { page: 0, pageSize: 10 },
  },
}

export interface IFingerspell {
  htaAnimation: string
  signId: string
  ref: DocumentReference | null

  //Id necessário para clicar e arrastar
  draggableId: string
  animationExported: boolean
  waitingRevision: boolean
  type: 'fingerspell' | 'sign'
  word?: string
}

export interface ISignWithHta extends ISign {
  htaAnimation: string
  signId: string
  ref: DocumentReference | null

  //Id necessário para clicar e arrastar
  draggableId: string
  animationExported: boolean
  waitingRevision: boolean
  type: 'fingerspell' | 'sign'

  word?: string
}

interface IManuallyTranslationWithId extends IManuallyTranslation {
  id?: string
  ref?: DocumentReference
}

const Translate: React.FC<ITranslateProps> = () => {
  const [isUpdating, setIsUpdating] = useState(false)
  const nmtSuggestionFns = useNmtSuggestion()[1]
  const [isLoading, setIsLoading] = useState(false)
  const [animationTime, setAnimationTime] = useState(0)
  const [data, setData] = useState<IManuallyTranslationWithId | null>(null)
  const [dataInPagination, setDataInPagination] = useState<IManuallyTranslationSentence[]>([])
  const [isPlaying, setIsPlaying] = useState<string | null>(null)
  const [currentHtaAnimations, setCurrentHtaAnimations] = useState<string[]>([])
  const [currentSignsToShow, setCurrentSignsToShow] = useState<
    {
      id: string
      glosa: string
      relatedWords?: string[]
      htaAnimation?: string
    }[]
  >()
  const setLocation = useLocation()[1]
  const fsUser = useFsUserDocData()
  const fsUserRef = useFsUserDocRef()
  const showSnackbar = useSnackbar()
  const nmtSign = useGetNmtSign()[0]
  const [, routeParams] = useRoute(routes.manuallyTranslationMain)
  const intl = useIntl()
  const manuallyTranslationId: string = routeParams?.id || ''
  const manuallyTranslationRef = getDocumentReference(fsUser.workspace, 'manuallyTranslations', manuallyTranslationId)
  const [activeData, setActiveData] = useState<ISignWithHta | IFingerspell | IManuallyTranslationTarget | null>(null)
  const [isCorpusReview, setIsCorpusReview] = useState(false)

  const firestore = useFirestore()

  const [paginationConfig, setPaginationConfig] = useState<IPaginationConfig>(initialPaginationConfig)
  const [translationPaginationConfig, setTranslationPaginationConfig] = useState(initialTranslationsPaginationConfig)
  const [translationsCount, setTranslationsCount] = useState(0)
  const [hasMoreResults, setHasMoreResults] = useState(false)
  const [hasMoreResultsTranslations, setHasMoreResultsTranslations] = useState(false)
  const [searchText, setSearchText] = useState('')
  const [signs, setSigns] = useState<(ISignWithHta | IFingerspell)[]>([])
  const [opennedDeleteDialog, setOpennedDeleteDialog] = useState(false)
  const [selectedSentence, setSelectedSentence] = useState<number | null>(null)
  const [expandedAccordions, setExpandedAccordions] = useState<string[]>([])
  const [nmtSearch, setNmtSearch] = useState(false)
  const [opennedRejectProject, setOpennedRejectProject] = useState(false)
  const [opennedTextEdit, setOpennedTextEdit] = useState(false)
  const [reasonText, setReasonText] = useState('')
  const [initialStatus, setInitialStatus] = useState('')
  const [textEdit, setTextEdit] = useState('')
  const nmtResultSigns = useRef<string[]>([])
  const [lastContainerId, setLastContainerId] = useState('')
  const signsRefCollection = getCollectionReference(fsUser.workspace, 'signs')
  const deletedLegacyPDIds = useRef<string[]>([])
  const [openTable, setOpenTable] = useState<boolean>(false)
  const [autoPlay, setAutoPlay] = useState(false)
  const [reportProblem, setReportProblem] = useState(false)
  const [signId, setSignId] = useState('')
  const [workspaceUsers, setWorkspaceUsers] = useState<Record<string, IUserInfo>>({})
  const [otherProblem, setOtherProblem] = useState('')
  //Query
  const initialSignQuery = query(
    signsRefCollection as Query<DocumentData>,
    where('signLanguageId', '==', fsUser.signLanguageId),
    where('oralLanguageId', '==', fsUser.oralLanguageId),
    where('searchTerms', 'array-contains', 'no_records_to_get'),
    where('_state', '==', 'ANIMATED'),
    orderBy('corpusPopularity', 'desc'),
    limit(1),
  )

  const [signsQueryState, setSignsQueryState] = useState<Query<DocumentData>>(initialSignQuery)
  const signsCollection = useFirestoreCollection(signsQueryState, {
    suspense: false,
  })
  const [openSignWarning, setOpenSignWarning] = useState(false)
  const [signsWarning, setSignsWarning] = useState<ISign[]>([])

  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        distance: 8,
      },
    }),
  )

  // Textos intl
  const textSearch = intl.get('messages.search')
  const timeText = intl.get('messages.time')
  const textNoSigns = intl.get('pages.validateTranslation.noSigns')
  const textAnimation = intl.get('pages.validateTranslation.animation')
  const textReport = intl.get('pages.validateTranslation.report')
  const textPlayAnimation = intl.get('messages.playAnimation')
  const textRotateAvatar = intl.get('messages.rotateAvatar')
  //

  const getUsers = async () => {
    const { data } = await getUsersByWorkspaceFunction({})
    setWorkspaceUsers(data.users)
  }

  const fetchSigns = useCallback(async () => {
    try {
      let signsQuery = query(getCollectionReference(fsUser.workspace, 'signs') as Query<DocumentData>)
      if (nmtSearch && searchText.trim()) {
        nmtResultSigns.current = (await nmtSign.getSignsXpresstranslation(searchText)) || []
        let nmtResult = [...nmtResultSigns.current]
        // Resgataremos de 10 em  10
        nmtResult = nmtResult.slice(paginationConfig.currentPage * 10, (paginationConfig.currentPage + 1) * 10)
        if (nmtResultSigns.current && nmtResultSigns.current.length) {
          signsQuery = query(signsQuery, where(documentId(), 'in', nmtResult))
          setSignsQueryState(signsQuery)
        }
      } else {
        if (searchText.trim()) {
          signsQuery = query(
            signsQuery,
            where('signLanguageId', '==', fsUser.signLanguageId),
            where('oralLanguageId', '==', fsUser.oralLanguageId),
            where('searchTerms', 'array-contains', searchText.trim()),
            where('_state', '==', 'ANIMATED'),
            orderBy('corpusPopularity', 'desc'),
          )
        }

        if (paginationConfig.lastOfList || paginationConfig.firstOfList) {
          if (paginationConfig.lastOfList) {
            signsQuery = query(signsQuery, startAfter(paginationConfig.lastOfList))
          } else {
            signsQuery = query(
              signsQuery,
              endBefore(paginationConfig.firstOfList),
              limitToLast(paginationConfig.rowsPerPage),
            )
          }
        }

        if (!paginationConfig.firstOfList) signsQuery = query(signsQuery, limit(paginationConfig.rowsPerPage))
      }

      setSignsQueryState(signsQuery)
      // const videosSnapshot = await getDocs(videosQuery)

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (err) {
      const error = err as Error
      showSnackbar(error.message, { variant: 'error' })
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paginationConfig, fsUser.workspace, searchText, signsQueryState])

  /**
   * Busca dados legados associados a uma tradução manual e os mescla com os dados existentes.
   * @param pData Os dados da tradução manual para os quais os dados legados devem ser buscados e mesclados.
   */
  const fetchLegacyPD = async (pData: IManuallyTranslation) => {
    if (!pData) return
    try {
      if (pData?.legacyPDIds?.length) {
        // Busca dados legados em lote
        const legacyPDData = await getInBatch<ILegacyPD>(
          fsUser.workspace as DocumentReference,
          'legacyPD',
          pData.legacyPDIds,
        )

        // Cria um mapa de dados legados para fácil acesso por ID
        const _legacyPdData: Record<string, ILegacyPD> = Object.fromEntries(Object.entries(legacyPDData))

        // Atualiza o contador de traduções
        setTranslationsCount(Object.keys(legacyPDData).length)

        // Coleta IDs únicos de sinais dos dados legados
        let signsIds: string[] = []
        for (const legacyPDId of Object.keys(_legacyPdData)) {
          signsIds = signsIds.concat(_legacyPdData[legacyPDId].signs)
        }
        signsIds = Array.from(new Set(signsIds))

        // Busca sinais e animações associadas em lote
        const signs = await getSignsBatch(
          fsUser.workspace as DocumentReference,
          signsIds.filter((el, index, self) => index === self.indexOf(el) && el !== ''),
        )
        const animationsIds = Object.values(signs).map((el: ISign) => el.currentAnimation?.animation?.id || '')
        const animations = await getAnimationBatch(
          fsUser.workspace as DocumentReference,
          animationsIds.filter((el, index, self) => index === self.indexOf(el) && el !== ''),
        )

        // Inicializa os dados a serem atualizados
        const _data = { ...pData }
        const translationSentences: IManuallyTranslationSentence[] = data?.translationSentences.length
          ? [...data?.translationSentences]
          : []

        // Itera sobre os dados legados e os mescla com os existentes
        for (const legacyPDId of Object.keys(_legacyPdData)) {
          const target: IManuallyTranslationTarget[] = []
          _legacyPdData[legacyPDId].signs.forEach((signId: string) => {
            const signData = signs[signId]
            const isFingerspell: boolean = !signData
            const animationId = signData?.currentAnimation?.animation?.id || ''
            target.push({
              animationExported: isFingerspell ? true : (signData.currentAnimation?.errors || []).includes('NO_ERRORS'),
              signId: signId,
              waitingRevision:
                !isFingerspell &&
                signData.currentAnimation &&
                signData.currentAnimationHasErrors === null &&
                signData._state === 'ANIMATED'
                  ? true
                  : false,
              type: isFingerspell ? 'fingerspell' : 'sign',
              word: isFingerspell ? signId : signData.glosa || signData.relatedWords[0],
              draggableId: uuidv4(),
              htaAnimation: animations[animationId]?.htaAnimation || '',
            })
          })

          // Verifica se já existe uma sentença de tradução com o mesmo ID de legado
          const index = translationSentences.findIndex((el) => el.legacyPDId == legacyPDId)
          const translationIndex = pData.translationSentences.findIndex((el) => el.legacyPDId == legacyPDId)

          // Cria ou atualiza a sentença de tradução conforme necessário
          const _translationSentence = {
            legacyPDId,
            source: _legacyPdData[legacyPDId].sentence,
            target: target,
          }
          if (index == -1) {
            translationSentences.push({
              id: uuidv4(),
              verified: translationIndex >= 0 ? pData.translationSentences[translationIndex].verified : false,
              ..._translationSentence,
            })
          } else if (
            index >= 0 &&
            !deepEqual({ id: translationSentences[index].id, ..._translationSentence }, translationSentences[index])
          ) {
            translationSentences[index] = {
              id: translationSentences[index].id,
              verified: translationSentences[index].verified,
              ..._translationSentence,
            }
          }

          _data.translationSentences = translationSentences
        }
        setData(_data)
      }
    } catch (err) {
      const error = err as Error
      showSnackbar(error.message, { variant: 'error' })
    }
  }

  const handleKeyUpSearch = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.keyCode === 13) {
      handleSearchSigns()
    }
  }

  useEffect(() => {
    if (paginationConfig.load) {
      fetchSigns()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paginationConfig])

  const handleSearchTextChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const text = (event.target && event.target.value) || ''
    setSearchText(text)
  }

  const handleSearchSigns = () => {
    const _paginationConfig = { ...paginationConfig }
    _paginationConfig.firstOfList = null
    _paginationConfig.lastOfList = null
    _paginationConfig.currentPage = 0
    _paginationConfig.load = true
    setPaginationConfig(_paginationConfig)
  }

  const handleChangePage = useCallback(
    (_event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null, page: number) => {
      const _paginationConfig = { ...paginationConfig }
      if (page > _paginationConfig.currentPage) {
        _paginationConfig.firstOfList = null
      } else {
        _paginationConfig.lastOfList = null
      }
      _paginationConfig.currentPage = page
      _paginationConfig.load = true
      setPaginationConfig(_paginationConfig)
    },
    [paginationConfig],
  )

  const handleChangeTranslationsPage = useCallback(
    (_event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null, page: number) => {
      const _translationPaginationConfig = { ...translationPaginationConfig }
      if (page > _translationPaginationConfig.currentPage) {
        _translationPaginationConfig.firstOfList =
          _translationPaginationConfig.firstOfList + _translationPaginationConfig.rowsPerPage
        _translationPaginationConfig.lastOfList =
          _translationPaginationConfig.lastOfList + _translationPaginationConfig.rowsPerPage
      } else {
        _translationPaginationConfig.firstOfList =
          _translationPaginationConfig.firstOfList - _translationPaginationConfig.rowsPerPage
        _translationPaginationConfig.lastOfList =
          _translationPaginationConfig.lastOfList - _translationPaginationConfig.rowsPerPage
      }
      _translationPaginationConfig.currentPage = page
      _translationPaginationConfig.load = true
      setTranslationPaginationConfig(_translationPaginationConfig)
      if (data) {
        setHasMoreResultsTranslations(
          (data.translationSentences?.length || 0) > _translationPaginationConfig.rowsPerPage * (page + 1),
        )
      }
    },
    [translationPaginationConfig, data],
  )

  const handleChangeRowsPerPage = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const _translationPaginationConfig = { ...translationPaginationConfig }
      _translationPaginationConfig.rowsPerPage = Number(event.target.value)
      _translationPaginationConfig.currentPage = 0
      _translationPaginationConfig.lastOfList = Number(event.target.value)
      _translationPaginationConfig.firstOfList = 0
      _translationPaginationConfig.load = true
      setTranslationPaginationConfig(_translationPaginationConfig)
    },
    [translationPaginationConfig],
  )

  /**
   * Busca traduções e atualiza os dados de paginação.
   * @function fetchTranslations
   */
  const fetchTranslations = useCallback(async () => {
    // Indica estado de carregamento
    setIsLoading(true)

    try {
      if (data) {
        // Busca dados paginados de sinais
        const paginatedData = await fetchSignDataPagination(
          data.translationSentences.slice(
            translationPaginationConfig.firstOfList,
            translationPaginationConfig.lastOfList,
          ),
        )
        setDataInPagination(paginatedData)

        // Atualiza configuração de paginação para indicar que o carregamento foi concluído
        setTranslationPaginationConfig((prevConfig) => ({
          ...prevConfig,
          load: false,
        }))
      }
    } finally {
      // Reseta estado de carregamento
      setIsLoading(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [translationPaginationConfig])

  useEffect(() => {
    if (translationPaginationConfig.load) {
      setIsLoading(true)
      fetchTranslations()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [translationPaginationConfig])

  /**
   * Retorna os dados de sinalização paginados, incluindo informações de animação.
   * @param _dataInPagination Array de objetos contendo dados de sinalização.
   * @returns Os dados de sinalização paginados.
   */
  const fetchSignDataPagination = async (
    _dataInPagination: IManuallyTranslationSentence[],
  ): Promise<IManuallyTranslationSentence[]> => {
    // Resgata ids dos sinais
    const signsIds: string[] = []
    for (const translation of _dataInPagination) {
      for (const target of translation.target || []) {
        signsIds.push(target.signId)
      }
    }

    // Recupera os sinais em batch para evitar múltiplas consultas
    const uniqueSignsIds = Array.from(new Set(signsIds.filter((id) => id !== '')))
    const signs = await getSignsBatch(fsUser.workspace as DocumentReference, uniqueSignsIds)

    // Recupera ids de animações únicos
    const animationsIds = Object.values(signs)
      .map((sign) => sign.currentAnimation?.animation?.id)
      .filter((id) => id) as string[]

    // Recupera as animações em batch
    const uniqueAnimationsIds = Array.from(new Set(animationsIds))
    const animations = await getAnimationBatch(fsUser.workspace as DocumentReference, uniqueAnimationsIds)

    // Atualiza os dados de tradução
    for (const translationSentence of _dataInPagination) {
      for (const target of translationSentence.target || []) {
        let _sign: ISign | undefined = signs[target.signId]

        if (!_sign) {
          const unitedWithSignsQuery = query(
            getCollectionReference(fsUser.workspace, 'signs') as Query<DocumentData>,
            where('unitedWithSigns', 'array-contains', target.signId),
          )
          const unitedWithSignsDocs = (await getDocs(unitedWithSignsQuery)).docs

          if (unitedWithSignsDocs.length > 0) {
            _sign = unitedWithSignsDocs[0].data() as ISign
          }
        }

        target.draggableId = uuidv4()
        target.htaAnimation = (_sign && animations[_sign.currentAnimation?.animation?.id || '']?.htaAnimation) || ''
        target.animationExported =
          target.type === 'fingerspell' ||
          (target.type === 'sign' && (_sign?.currentAnimation?.errors || []).includes('NO_ERRORS'))
        target.waitingRevision =
          target.type === 'sign' &&
          _sign?.currentAnimation &&
          _sign.currentAnimationHasErrors === null &&
          _sign._state === 'ANIMATED'
            ? true
            : false
      }
    }

    return _dataInPagination
  }

  /**
   * Busca os dados de tradução manual associados a um determinado ID e atualiza o estado do componente.
   * @param id O ID do objeto de tradução manual a ser buscado.
   */
  const fetchManuallyTranslation = async (id: string) => {
    setIsLoading(true)
    try {
      // Busca os dados de tradução manual no banco de dados
      const manuallyTranslationRef = getDocumentReference(fsUser.workspace, 'manuallyTranslations', id)
      const manuallyTranslationSnapshot = await getDoc(manuallyTranslationRef as DocumentReference)
      const manuallyTranslationData = manuallyTranslationSnapshot.data() as IManuallyTranslationWithId

      // Define o status inicial com base nos dados obtidos
      setInitialStatus(manuallyTranslationData?.status)
      const _data = { ...manuallyTranslationData }

      // Criação de IDs únicos para as sentenças de tradução
      _data.translationSentences.forEach((el) => (el.id = uuidv4()))

      // Atualiza o contador de traduções
      setTranslationsCount(Math.ceil(_data.translationSentences.length))

      _data.ref = manuallyTranslationRef as DocumentReference
      _data.id = id

      // Verifica se há diferenças entre os IDs de legado e as sentenças de tradução
      if (
        (_data.legacyPDIds && _data.legacyPDIds.length) ||
        (_data.translationSentences &&
          _data.translationSentences.length &&
          _data.legacyPDIds &&
          _data.legacyPDIds.length &&
          _data.translationSentences.length != _data.legacyPDIds.length)
      ) {
        // Se houver diferenças, busca dados adicionais de legado
        await fetchLegacyPD(_data)
      } else {
        // Se não houver diferenças, mescla dados locais com dados paginados
        _data.translationSentences = [
          ...new Set([
            ..._data.translationSentences,
            ..._data.translationSentences.slice(
              translationPaginationConfig.firstOfList,
              translationPaginationConfig.lastOfList,
            ),
          ]),
        ]
        setData(_data)
      }
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    if (manuallyTranslationId) fetchManuallyTranslation(manuallyTranslationId)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [manuallyTranslationId])

  const handleBackToList = () => {
    if (isUpdating && !confirm(intl.get('messages.confirmBackToList'))) return
    setLocation(routes.manuallyTranslation)
  }

  const toggleOpenDeleteDialog = () => {
    setOpennedDeleteDialog(!opennedDeleteDialog)
  }

  const getAnimationBatch = async (workspaceRef: DocumentReference, animationsIds: string[]) => {
    const result: Record<string, IAnimation> = {}

    const batch: string[][] = []
    const batchNumber = 9
    for (let i = 0; i < animationsIds.length; i = i + batchNumber) {
      batch.push(animationsIds.slice(i, i + batchNumber))
    }
    for (const animations of batch) {
      const animationsQuery = query(collection(workspaceRef, 'animations'), where(documentId(), 'in', animations))
      const animationsSnapshot = await getDocs(animationsQuery)
      animationsSnapshot.docs.forEach((animationSnapshot) => {
        result[animationSnapshot.id] = animationSnapshot.data() as IAnimation
      })
    }
    return result
  }

  const handlePlayAnimation = (
    item?: IManuallyTranslationSentence | null,
    itemSearch?: ISignWithHta | IFingerspell,
  ) => {
    if (!data && !itemSearch) return
    let animations: string[] = []
    const _signs: {
      id: string
      glosa: string
      relatedWords?: string[]
      htaAnimation?: string
    }[] = []
    let _htaAnimation = ''
    if (!isPlaying) {
      if (itemSearch) {
        if (itemSearch.type === 'fingerspell') {
          animations = animations.concat(parseFingerspell(fsUser.workspace.id, itemSearch.word || ''))
        } else {
          animations.push(itemSearch.htaAnimation || '')
        }
      } else if (item) {
        item.target?.map((target: IManuallyTranslationTarget) => {
          if (target.type === 'fingerspell') {
            animations = animations.concat(parseFingerspell(fsUser.workspace.id, target.word))
          } else {
            _htaAnimation = target.htaAnimation || ''
            animations.push(target.htaAnimation || '')
          }

          _signs.push({
            id: target.signId,
            glosa: target.word,
            htaAnimation: _htaAnimation,
          })
        })
      } else {
        data?.translationSentences.forEach((item: IManuallyTranslationSentence) => {
          item.target?.forEach((target: IManuallyTranslationTarget) => {
            // caso seja datilologia trata a datilologia
            if (target.type === 'fingerspell') {
              animations = animations.concat(parseFingerspell(fsUser.workspace.id, target.word))
            } else {
              _htaAnimation = target.htaAnimation || ''
              animations.push(target.htaAnimation || '')
            }

            _signs.push({
              id: target.signId,
              glosa: target.word,
              htaAnimation: _htaAnimation,
            })
          })
        })
      }
    }
    setCurrentHtaAnimations(animations)
    setCurrentSignsToShow(_signs)
    let seconds = 1
    intervalRef = setInterval(() => {
      setAnimationTime(seconds)
      seconds++
    }, 1000)
    setIsPlaying(itemSearch ? itemSearch.draggableId : item ? item.id || '' : 'GERAL')
  }

  const handleGetSuggestions = async (index: number | null) => {
    try {
      if (index === null || !data) return

      const _data = { ...data }

      const translationSentences = [..._data.translationSentences]
      const item = translationSentences[index]
      const nmtResult = await nmtSuggestionFns.setSentence(item.source)
      const nmtResultTranslation = nmtResult.translation
      const nmtResultVersion = nmtResult.nmt_version
      if (!item) return
      const suggestions: IManuallyTranslationTarget[] = []
      const signsIds = nmtResultTranslation.map((el) => el.signRef?.id || '')

      const signs = await getSignsBatch(
        fsUser.workspace as DocumentReference,
        signsIds.filter((el, index, self) => index === self.indexOf(el) && el !== ''),
      )

      const animationsIds = Object.values(signs).map((el: ISign) => el.currentAnimation?.animation?.id || '')

      const animations = await getAnimationBatch(
        fsUser.workspace as DocumentReference,
        animationsIds.filter((el, index, self) => index === self.indexOf(el) && el !== ''),
      )

      nmtResultTranslation.map((el) => {
        const signValue = el.signRef?.id || el.text || ''
        const type = el.text ? 'fingerspell' : 'sign'

        if (type === 'sign' && !signs[signValue]) {
          feedbackFunction({
            text: `Sinal retornado pela NMT não encontrado no 
            firestore\nWorkspace: ${fsUser.workspace.id}\n
            Id: ${signValue}\nFrase: ${item.source}\nVersão: ${nmtResultVersion}`,
          })
          showSnackbar(intl.get('pages.manuallyTranslation.thereAreSignalsNotFoundInOurDatabase'), { variant: 'info' })
        } else {
          suggestions.push({
            draggableId: uuidv4(),
            signId: signValue,
            type,
            word: el.text || signs[signValue].glosa,
            htaAnimation:
              (signs[signValue] &&
                animations[signs[signValue].currentAnimation?.animation?.id || ''] &&
                animations[signs[signValue].currentAnimation?.animation?.id || ''].htaAnimation) ||
              '',
            animationExported:
              type === 'fingerspell' ||
              (type === 'sign' && (signs[signValue].currentAnimation?.errors || []).includes('NO_ERRORS')),
            waitingRevision:
              type === 'sign' &&
              signs[signValue].currentAnimation &&
              signs[signValue].currentAnimationHasErrors === null &&
              signs[signValue]._state === 'ANIMATED'
                ? true
                : false,
          })
        }
      })

      item.suggestions = suggestions
      translationSentences[index] = item

      _data.translationSentences = translationSentences
      setData(_data)
      setNmtSearch(true)
      setSearchText(item.source)
      handleSearchSigns()
    } catch (err) {
      const e = err as Error
      showSnackbar(e.message, { variant: 'error' })
    }
  }

  const handleGetTranslationFromNMT = (index: number | null) => {
    if (index === null) return

    handleGetSuggestions(index)
  }

  const handleConfirmDeleteSentence = () => {
    setOpennedDeleteDialog(false)
    try {
      if (!data || selectedSentence === null) return
      const _data = { ...data }
      const translationSentences = [..._data.translationSentences]
      if (translationSentences[selectedSentence] && translationSentences[selectedSentence].legacyPDId) {
        deletedLegacyPDIds.current.push(translationSentences[selectedSentence].legacyPDId || '')
      }

      translationSentences.splice(selectedSentence, 1)
      _data.translationSentences = translationSentences
      setData(_data)
    } finally {
      setSelectedSentence(null)
    }
  }

  const handleSaveLegacyPd = async (pData: IManuallyTranslation) => {
    if (!pData) return
    const op: IBatchOperation[] = []

    deletedLegacyPDIds.current.forEach((legacyPDId) => {
      const legacyPdRef = getDocumentReference(fsUser.workspace, 'legacyPD', legacyPDId)
      op.push({
        op: 'update',
        ref: legacyPdRef,
        data: {
          state: 'REJECTED',
          revision: {
            user: fsUserRef,
            lastUpdate: Timestamp.now(),
          },
        },
      })
    })

    if (pData.translationSentences && pData.translationSentences.length) {
      pData.translationSentences.forEach((el) => {
        if (el.legacyPDId) {
          const legacyPdRef = getDocumentReference(fsUser.workspace, 'legacyPD', el.legacyPDId || '')
          const signsLegacy = el.target.map((el) => el.signId)
          op.push({
            op: 'update',
            data: {
              state: el.verified ? 'VERIFIED' : 'QUARANTINE',
              revision: {
                user: fsUserRef,
                lastUpdate: Timestamp.now(),
              },
              signs: signsLegacy,
              sentence: el.source,
            },
            ref: legacyPdRef,
          })
        }
      })
    }

    if (op.length) {
      await runBatchOperationsFirestore(firestore, op)
    }
    deletedLegacyPDIds.current = []
  }

  const handleDeleteSentence = (index: number) => {
    setSelectedSentence(index)
    setOpennedDeleteDialog(true)
  }

  const checkIfExistsAnimationNotExported = (translationSentence: IManuallyTranslationSentence) => {
    return translationSentence.target?.filter((el) => !el.animationExported).length > 0
  }

  const handleVerifyTranslation = (id: string) => {
    if (!data) return
    const _data = { ...data }
    const translationSentences = [..._data.translationSentences]
    const idx = translationSentences.findIndex((el) => {
      return el.id == id
    })

    if (!translationSentences[idx].legacyPDId && checkIfExistsAnimationNotExported(translationSentences[idx])) {
      showSnackbar(intl.get('pages.manuallyTranslation.itIsNotPossibleVerifyTranslationWithAnimationError'), {
        variant: 'warning',
      })
      return
    }
    translationSentences[idx].verified = !translationSentences[idx].verified
    _data.translationSentences = translationSentences

    setData(_data)
  }

  const handleSave = async () => {
    setIsLoading(true)
    try {
      if (!data) return

      const dataToSave = cloneDeep(data)

      /** Tratamento dos dados antes de salvar
       * Remove ids das traduções
       * Remove os ids dos sinais
       */
      const max = translationsCount || dataToSave.translationSentences?.length || 0
      let progress = 0

      delete dataToSave['ref']
      delete dataToSave['id']

      dataToSave.translationSentences.forEach((translations: IManuallyTranslationSentence) => {
        if (translations.verified) {
          progress++
        }
        delete translations['suggestions']
        delete translations['id']
        translations.target?.forEach((target: IManuallyTranslationTarget) => {
          delete target['draggableId']
          delete target['htaAnimation']
        })
      })
      const newProgress = (100 / max) * progress
      dataToSave.progress = Number(newProgress.toFixed(2))

      dataToSave.searchTerms = preProcess(dataToSave.projectName, fsUser.workspace.id, true, false, false, false)

      if (dataToSave.progress >= 100) {
        dataToSave.status = 'finalized'
        //Envia para produto

        setInitialStatus(dataToSave.status)

        // Faz consulta dos sinais em batch para enviar sempre dados atualizados
        const signsIds = dataToSave.translationSentences.map((el) => (el.target.length == 1 ? el.target[0].signId : ''))

        const _signs: Record<string, ISign> = await getSignsBatch(
          fsUser.workspace as DocumentReference,
          signsIds.filter((el) => el !== ''),
        )

        const _signsWarning: ISign[] = []

        Object.entries(_signs).forEach((sign) => {
          // Checa se tem sinais sem referencia ou estão aguardando revisão de animação
          if (
            sign[1].searchReference === '' ||
            (!sign[1].isFingerspell &&
              sign[1].currentAnimation &&
              sign[1].currentAnimationHasErrors === null &&
              sign[1]._state === 'ANIMATED')
          ) {
            if (signsWarning.findIndex((el) => el.id === sign[0]) === -1) {
              _signsWarning.push({ ...sign[1], id: sign[0] })
            }
          }
        })

        // Caso os sinais tenham alguma pendencia, solicitamos a correção imediata e não seja RC
        if (_signsWarning.length && dataToSave.type != 'revisao-corpus') {
          setSignsWarning(_signsWarning)
          setOpenSignWarning(true)
          throw new Error(intl.get('pages.manuallyTranslation.thereAreSignsAwaitingAnimationReviewOrNoSearchReference'))
        }
        finalizeCMSProject(manuallyTranslationId, {
          data: dataToSave,
          signsData: _signs,
        })
      } else if (dataToSave.progress > 0) {
        dataToSave.status = 'inProgress'

        //Envia para produto
        if (initialStatus !== dataToSave.status) {
          setInitialStatus(dataToSave.status)
          changeCMSStatus({
            id: manuallyTranslationId,
            userName: fsUser.displayName || '',
            status: dataToSave.status,
            uid: dataToSave.uid,
            projectName: dataToSave.projectName || '',
          })
        }
      }

      await updateManuallyTranslation(manuallyTranslationRef, dataToSave)
      handleSaveLegacyPd(dataToSave)
      setIsUpdating(false)

      showSnackbar(intl.get('messages.savedSuccessfully'), {
        variant: 'success',
      })
    } catch (err) {
      const error = err as Error
      showSnackbar(error.message, { variant: 'error' })
    } finally {
      setIsLoading(false)
    }
  }

  const handleDeleteChip = (item: IManuallyTranslationSentence, indexChip: number) => {
    if (!data) return
    const _data = { ...data }
    const translationSentences = [..._data.translationSentences]
    const dataValues = [...item.target]
    dataValues.splice(indexChip, 1)
    item.target = dataValues
    const index = translationSentences.findIndex((el) => el.id === item.id)
    translationSentences[index] = item
    _data.translationSentences = translationSentences
    setData(_data)
  }

  const handleAddSentence = (index: number) => {
    if (!data) return
    const _data = { ...data }
    const translationSentences = [..._data.translationSentences]

    const dataToAdd = {
      id: uuidv4(),
      source: '',
      target: [],
      verified: false,
    }
    translationSentences.splice(index, 0, dataToAdd)
    _data.translationSentences = translationSentences
    setData(_data)
  }

  useEffect(() => {
    if (isUpdating) {
      window.onbeforeunload = (event: BeforeUnloadEvent) => {
        event.preventDefault()
        return confirm(intl.get('messages.confirmRefreshPage'))
      }
    } else {
      window.onbeforeunload = null
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isUpdating])

  useEffect(() => {
    if (data) {
      const call = async () => {
        setDataInPagination(
          await fetchSignDataPagination([
            ...data.translationSentences.slice(
              translationPaginationConfig.firstOfList,
              translationPaginationConfig.lastOfList,
            ),
          ]),
        )
        setHasMoreResultsTranslations(
          (data.translationSentences?.length || 0) >
            translationPaginationConfig.rowsPerPage * (translationPaginationConfig.currentPage + 1),
        )
      }
      call()
    }

    if (!isLoading && data) {
      setIsUpdating(true)
    }

    if (data && data.legacyPDIds && data.legacyPDIds.length > 0) setIsCorpusReview(true)
    else setIsCorpusReview(false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data])

  useEffect(() => {
    const call = async () => {
      let _signs: (ISignWithHta | IFingerspell)[] = []

      const _paginationConfig = { ...paginationConfig }
      if (!signsCollection.data) return
      _paginationConfig.lastOfList = signsCollection.data.docs[signsCollection.data.docs.length - 1]
      _paginationConfig.firstOfList = signsCollection.data.docs[0]

      _paginationConfig.load = false

      // Adiciona sempre a datilologia antes
      if (searchText) {
        const onlyLettersAndNumbers: boolean = /^[A-Za-z0-9]*$/.test(searchText)

        let _searchText = searchText
        if (!onlyLettersAndNumbers) {
          _searchText = searchText
            .split(' ')
            .join(',')
            .split('.')
            .join(',')
            .split('/')
            .join(',')
            .split('(')
            .join(',')
            .split(')')
            .join(',')
            .split('$')
            .join(',')
            .split(':')
            .join(',')
            .split('%')
            .join(',')
            .split('-')
            .join(',')
            .split("'")
            .join(',')
            .split(',')
            .toString()
            .replaceAll(',', ' ')
        }

        const fingerspellData: IFingerspell = {
          signId: _searchText,
          ref: null,
          type: 'fingerspell',
          htaAnimation: '',
          draggableId: uuidv4(),
          waitingRevision: false,
          word: _searchText,
          animationExported: true,
        }
        //
        _signs.push(fingerspellData)
      }

      const animationsIds: string[] = []
      for (const signSnapshot of signsCollection.data.docs) {
        const signData = signSnapshot.data() as ISignWithHta
        signData.signId = signSnapshot.id
        signData.type = signData.isFingerspell ? 'fingerspell' : 'sign'
        signData.ref = signSnapshot.ref
        signData.draggableId = uuidv4()
        signData.word = signData.glosa || signData.relatedWords[0]
        signData.animationExported = (signData.currentAnimation?.errors || []).includes('NO_ERRORS')
        signData.waitingRevision =
          !signData.isFingerspell && signData.currentAnimationHasErrors === null && signData._state === 'ANIMATED'
            ? true
            : false
        animationsIds.push(signData.currentAnimation?.animation?.id || '')

        _signs.push(signData)
      }

      const animations = await getAnimationBatch(
        fsUser.workspace as DocumentReference,
        animationsIds.filter((el, index, self) => index === self.indexOf(el) && el !== ''),
      )
      // TODO - Falta vê esse type
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      _signs.forEach((el: any) => {
        if (el.currentAnimation?.animation && animations[el.currentAnimation?.animation.id || ''])
          el.htaAnimation = animations[el.currentAnimation?.animation.id || ''].htaAnimation || ''
      })

      setPaginationConfig(_paginationConfig)

      // Ordena de acordo com o retorno da nmt
      if (nmtResultSigns.current && nmtResultSigns.current.length)
        _signs = _signs.sort(
          (el1, el2) =>
            nmtResultSigns.current.findIndex((el) => el === el1.signId) -
            nmtResultSigns.current.findIndex((el) => el === el2.signId),
        )
      setSigns(_signs)
      nmtResultSigns.current = []

      setHasMoreResults(paginationConfig.rowsPerPage === _signs.length - 1)
    }

    call()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [signsCollection.data])

  useEffect(() => {
    getUsers()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleFinalizeAnimation = () => {
    setIsPlaying(null)
    if (intervalRef) clearInterval(intervalRef)
  }

  const handleChangeOrderAccordions = (oldIndex: number, newIndex: number) => {
    if (!data) return
    const _data = { ...data }
    const translationSentences = [..._data.translationSentences]

    translationSentences.splice(oldIndex, 0, translationSentences.splice(newIndex, 1)[0])
    _data.translationSentences = translationSentences
    setData(_data)
  }

  const handleDragEnd = ({ over, active }: DragEndEvent) => {
    if ((!over && !lastContainerId) || !data) {
      setActiveData(null)
      return
    }
    if (!active.data.current?.sortable || (active.data.current?.sortable && !active.data.current?.sortable.containerId))
      return
    const activeContainer = active.data.current?.sortable.containerId
    const activeContainerType = activeContainer.split('--')[0]
    const activeContainerId = activeContainer.split('--')[1]

    if (activeContainerType !== 'accordions' && over?.id && active.id !== over?.id) {
      if (!expandedAccordions.includes(activeContainerId)) return
      const overContainer = over.data.current?.sortable.containerId || over.id
      const activeIndex = active.data.current?.sortable.index
      if (activeContainer === overContainer) {
        const _data = { ...data }

        const translationSentences = [..._data.translationSentences]

        const index = translationSentences.findIndex((el) => el.id === activeContainerId)

        if (index < 0) return

        const item = translationSentences[index]
        const dataValues = [...item.target]

        const overIndex = over.id in dataValues ? dataValues.length + 1 : over.data.current?.sortable.index
        dataValues.splice(overIndex, 0, dataValues.splice(activeIndex, 1)[0])
        item.target = dataValues
        translationSentences[index] = item

        _data.translationSentences = translationSentences
        setData(_data)
      }
    }

    const indexSign = signs.findIndex((el) => el.draggableId === activeData?.draggableId)
    if (indexSign >= 0) {
      // Renova o id para conseguir duplicar o sinal caso venha da pesquisa
      const _signs = [...signs]
      _signs[indexSign].draggableId = uuidv4()
      setSigns(_signs)
    }

    const overContainer = lastContainerId || over?.data.current?.sortable.containerId || '0'

    const overContainerId = overContainer.split('--').length > 0 ? overContainer.split('--')[1] : ''

    if (overContainerId) {
      const _data = { ...data }

      const translationSentences = [..._data.translationSentences]

      const index = translationSentences.findIndex((el) => el.id === overContainerId)

      const suggestions = (translationSentences[index] && translationSentences[index].suggestions) || []

      const indexNMTSuggestionItem = suggestions.findIndex((el) => el.draggableId === activeData?.draggableId)
      if (indexNMTSuggestionItem >= 0) {
        // Renova o id para conseguir duplicar o sinal caso venha da pesquisa
        suggestions[indexNMTSuggestionItem].draggableId = uuidv4()
        _data.translationSentences[index].suggestions = suggestions
        setData(_data)
      }
    }

    setActiveData(null)
    setLastContainerId('')
  }

  const handleDragOver = ({ over, active }: DragOverEvent) => {
    const overId = over?.id

    if (!over || !data) {
      return
    }

    if (!active.data.current?.sortable || (active.data.current?.sortable && !active.data.current?.sortable.containerId))
      return

    const activeContainer = active.data.current?.sortable.containerId

    const activeContainerType = activeContainer.split('--')[0]

    const overContainer =
      (activeContainerType === 'accordions' || over?.data.current?.sortable.index == -1) && overId
        ? overId
        : over?.data.current?.sortable.containerId || 0

    if (activeContainerType === 'accordions') {
      handleChangeOrderAccordions(over?.data.current?.sortable.index, active.data.current?.sortable.index)
    } else if (
      activeContainer !== overContainer &&
      overContainer &&
      (activeContainerType === 'searchdata' || (activeContainerType === 'chiplist' && activeData))
    ) {
      const overContainerId = overContainer.split('--')[1]

      if (!expandedAccordions.includes(overContainerId)) return

      const _data = { ...data }

      const translationSentences = [..._data.translationSentences]

      const index = translationSentences.findIndex((el) => el.id === overContainerId)

      if (index < 0) return

      const item = translationSentences[index]

      const dataValues = [...(item?.target || [])]

      let overIndex = 0
      if (dataValues.length > 0 && overId)
        overIndex = overId in dataValues ? dataValues.length + 1 : over?.data.current?.sortable.index
      const itemData =
        activeData ||
        signs[active.data.current?.sortable.index] ||
        (item?.suggestions && item.suggestions[active.data.current?.sortable.index])
      const itemToAdd: IManuallyTranslationTarget = {
        draggableId: itemData.draggableId,
        signId: itemData.signId || '',
        type: itemData.type,
        word: itemData.word || '',
        htaAnimation: itemData.htaAnimation,
        animationExported: itemData.animationExported,
        waitingRevision: itemData.waitingRevision,
      }

      setLastContainerId((overId || '').toString())

      if (activeData) {
        // Procura e remove o que foi salvo anteriormente
        translationSentences.forEach((translations) => {
          translations.target?.forEach((target, indexChip) => {
            if (target.draggableId === activeData.draggableId) {
              translations.target.splice(indexChip, 1)
            }
          })
        })
      }

      dataValues.splice(overIndex, 0, itemToAdd)

      item.target = dataValues

      translationSentences[index] = item

      _data.translationSentences = translationSentences
      setData(_data)
    }
  }

  const handleChangeUseNmtCheck = (checked: boolean) => {
    setNmtSearch(checked)
  }

  const handleDragStart = ({ active }: DragStartEvent) => {
    const activeContainer = active.data.current?.sortable.containerId
    const activeContainerType = activeContainer.split('--')[0]
    const activeContainerId = activeContainer.split('--')[1]
    const activeContainerOverId = activeContainer.split('--').length > 2 ? activeContainer.split('--')[2] : ''
    let _activeData: ISignWithHta | IFingerspell | IManuallyTranslationTarget | null = null

    if (activeContainerType === 'searchdata' && activeContainerId === '1') {
      _activeData = signs.find((el) => el.draggableId == active?.id) || null
    } else if (activeContainerType === 'searchdata' && activeContainerId === '2' && data) {
      const translationSentences = [...data.translationSentences]

      const index = translationSentences.findIndex((el) => el.id === activeContainerOverId)

      const suggestions = (translationSentences[index] && translationSentences[index].suggestions) || []

      _activeData = suggestions.find((el) => el.draggableId == active?.id) || null
    } else if (activeContainerType === 'accordions') {
      setExpandedAccordions([])
    } else if (activeContainerType === 'chiplist' && data) {
      const translationSentences = [...data.translationSentences]

      const index = translationSentences.findIndex((el) => el.id === activeContainerId)

      const targets = (translationSentences[index] && translationSentences[index].target) || []

      _activeData = targets.find((el) => el.draggableId == active?.id) || null
    }
    setActiveData(_activeData)
  }

  const handleDragCancel = () => {
    setActiveData(null)
  }

  const handleRejectRequest = (
    uid: string,
    ref: DocumentReference | null,
    interpreter: DocumentReference[],
    errors: IManuallyTranslationErrors[],
    projectName: string,
  ) => {
    if (!reasonText) {
      showSnackbar(intl.get('pages.manuallyTranslation.reasonNotStated'), {
        variant: 'error',
      })
      return
    }

    if (ref) {
      const _interpreter = [...interpreter]
      _interpreter.push(fsUserRef)

      const _errors = [...errors]
      _errors.push({
        message: reasonText,
        interpreter: fsUserRef,
        createdAt: Timestamp.fromDate(new Date()),
      })

      updateManuallyTranslation(ref, {
        errors: _errors,
        status: 'rejected',
        interpreter: _interpreter,
      })

      setInitialStatus('rejected')
    }

    changeCMSStatus({
      id: manuallyTranslationId,
      uid,
      status: 'rejected',
      userName: fsUser.displayName || '',
      message: reasonText,
      projectName: projectName,
    })
    setReasonText('')
  }

  const handleApproveRequest = async (
    uid: string,
    ref: DocumentReference | null,
    interpreter: DocumentReference[],
    projectName: string,
  ) => {
    if (initialStatus === 'approved') return
    try {
      if (ref) {
        const _interpreter = [...interpreter]
        _interpreter.push(fsUserRef)

        await updateManuallyTranslation(ref, {
          status: 'approved',
          interpreter: _interpreter,
        })
      }
      setInitialStatus('approved')

      await changeCMSStatus({
        id: manuallyTranslationId,
        uid,
        status: 'approved',
        userName: fsUser.displayName || '',
        projectName: projectName,
      })

      showSnackbar(intl.get('pages.manuallyTranslation.successfullyApproved'), {
        variant: 'success',
      })
    } catch (err) {
      showSnackbar(intl.get('messages.failed'), {
        variant: 'error',
      })
    }
  }

  const handleGetSignByClick = (item: IManuallyTranslationTarget) => {
    const signRef: DocumentReference | null = item.signId
      ? (getDocumentReference(fsUser.workspace, 'signs', item.signId) as DocumentReference)
      : null
    const _item: IFingerspell | ISignWithHta = {
      signId: item.signId,
      ref: signRef,
      type: item.type,
      htaAnimation: item.htaAnimation || '',
      draggableId: uuidv4(),
      word: item.word,
      animationExported: item.animationExported,
      waitingRevision: item.waitingRevision,
    }
    setSigns([_item])
  }
  const toggleOpennedRejectProjectDialog = () => {
    setOpennedRejectProject(!opennedRejectProject)
  }

  const toggleOpennedTextEdit = () => {
    setOpennedTextEdit(!opennedTextEdit)
  }

  const handleSaveEditedText = () => {
    if (!data) return
    const _data = { ...data }

    const translationSentences: IManuallyTranslationSentence[] = textEdit
      .split('\n')
      .map((el) => {
        return {
          id: uuidv4(),
          source: el,
          target: [],
          verified: false,
        } as IManuallyTranslationSentence
      })
      .filter((el) => el.source)
    _data.translationSentences = translationSentences
    setData(_data)
  }

  const handleClickPlaySign = async (signId: string) => {
    setAutoPlay(true)
    setIsPlaying('')

    const _htaAnimations: string[] = []
    if (!currentSignsToShow) return
    const signIndex = currentSignsToShow.findIndex((el) => {
      return el.id == signId
    })

    _htaAnimations.push(currentSignsToShow[signIndex].htaAnimation || '')
    setCurrentHtaAnimations(_htaAnimations)
  }

  const handleClickOpenSigns = () => {
    setOpenTable(!openTable)
  }

  const handleClickReportProblem = async (signId: string) => {
    setSignId(signId)
    setReportProblem(!reportProblem)
  }

  const droppableAccordionList = useMemo(() => {
    return (
      <>
        {!isLoading && (
          <DroppableAccordionList
            data={data}
            setData={setData}
            onAddSentence={handleAddSentence}
            onDeleteSentence={handleDeleteSentence}
            onVerifyTranslation={handleVerifyTranslation}
            onPlayAnimation={handlePlayAnimation}
            isPlaying={isPlaying}
            onFinalizeAnimation={handleFinalizeAnimation}
            onGetTranslationFromNMT={handleGetTranslationFromNMT}
            onDeleteChip={handleDeleteChip}
            expandedAccordions={expandedAccordions}
            setExpandedAccordions={setExpandedAccordions}
            onGetSignByClick={handleGetSignByClick}
            dataInPagination={dataInPagination}
            firstOfList={translationPaginationConfig.firstOfList}
          />
        )}
      </>
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading, isPlaying, dataInPagination, data, expandedAccordions, translationPaginationConfig.firstOfList])

  return (
    <Layout title={intl.get('modules.manuallyTranslation')}>
      <DndContext
        onDragStart={handleDragStart}
        onDragCancel={handleDragCancel}
        onDragOver={handleDragOver}
        onDragEnd={handleDragEnd}
        sensors={sensors}
      >
        <Grid
          item
          container
          md={10}
          direction="row"
          style={{
            marginTop: '10px',
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <Grid
            item
            container
            md={9}
            direction="column"
            style={{
              gap: '20px',
              display: 'flex',
            }}
          >
            <Grid
              item
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'flex-end',
              }}
            >
              <Box
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  gap: '10px',
                  alignItems: 'center',
                }}
              >
                <Tooltip title={intl.get('messages.back')}>
                  <Fab
                    style={{ paddingLeft: '8px' }}
                    onClick={() => {
                      handleBackToList()
                    }}
                  >
                    <ArrowBackIcon />
                  </Fab>
                </Tooltip>
                <Box>
                  <Typography variant={'h5'}>{data?.projectName}</Typography>
                  <Typography variant={'subtitle1'}>
                    {cmsTypesName[data?.type || '']
                      ? intl.get(`pages.manuallyTranslation.typeList.${cmsTypesName[data?.type || '']}`)
                      : ''}
                  </Typography>
                </Box>
              </Box>

              <Box style={{ display: 'flex', gap: '10px' }}>
                <Tooltip title={intl.get('pages.manuallyTranslation.approveRequest')}>
                  <Fab
                    size={'small'}
                    onClick={(ev) => {
                      ev.stopPropagation()
                      handleApproveRequest(
                        data?.uid || '',
                        data?.ref || null,
                        data?.interpreter || [],
                        data?.projectName || '',
                      )
                    }}
                  >
                    <ThumbUpAltIcon />
                  </Fab>
                </Tooltip>
                <Tooltip title={intl.get('pages.manuallyTranslation.rejectRequest')}>
                  <Fab
                    size={'small'}
                    onClick={(ev) => {
                      ev.stopPropagation()
                      if (initialStatus === 'rejected') return
                      setOpennedRejectProject(true)
                    }}
                  >
                    <ThumbDownAltIcon />
                  </Fab>
                </Tooltip>
                <Tooltip title={intl.get('messages.editText')}>
                  <Fab
                    size={'small'}
                    disabled={isCorpusReview}
                    onClick={(ev) => {
                      ev.stopPropagation()
                      setTextEdit(data?.translationSentences.map((el) => el.source).join('\n') || '')
                      setOpennedTextEdit(true)
                    }}
                  >
                    <EditIcon />
                  </Fab>
                </Tooltip>
                <Tooltip title={intl.get('messages.save')}>
                  <Fab size="small" color="primary" onClick={handleSave}>
                    <SaveIcon />
                  </Fab>
                </Tooltip>
                <Tooltip title={intl.get(isPlaying === 'GERAL' ? 'messages.stopAnimation' : 'messages.playAnimation')}>
                  <Fab
                    size="small"
                    color="primary"
                    onClick={() => {
                      if (isPlaying === 'GERAL') {
                        handleFinalizeAnimation()
                      } else {
                        handlePlayAnimation()
                      }
                    }}
                    disabled={isPlaying !== null && isPlaying !== 'GERAL'}
                  >
                    {isPlaying === 'GERAL' ? <StopIcon /> : <PlayArrowIcon />}
                  </Fab>
                </Tooltip>
              </Box>
            </Grid>
            <Grid item style={{ width: '100%' }}>
              <Paper
                style={{
                  width: '100%',
                  display: 'flex',
                  gap: '20px',
                  flexDirection: 'column',
                  padding: '10px',
                  overflow: 'auto',
                  height: '75vh',
                }}
              >
                <>
                  {isLoading && (
                    <StyledLoaderWrapper>
                      <StyledLoader>
                        <CircularProgress />
                        <Typography>{intl.get('messages.loadingData')}</Typography>
                      </StyledLoader>
                    </StyledLoaderWrapper>
                  )}
                  {droppableAccordionList}
                </>
              </Paper>
              <TablePagination
                style={{
                  pointerEvents: 'auto',
                  overflow: 'hidden',
                  minHeight: '50px',
                  display: 'flex',
                  justifyContent: 'center',
                }}
                component="div"
                count={data?.translationSentences?.length || 0}
                rowsPerPage={translationPaginationConfig.rowsPerPage}
                page={translationPaginationConfig.currentPage}
                onPageChange={handleChangeTranslationsPage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                rowsPerPageOptions={[10, 20, 30]}
                labelRowsPerPage={intl.get('messages.labelRows')}
                labelDisplayedRows={({ from, to }) =>
                  intl.get('pages.animation.labelDisplayedRowsAll', {
                    from,
                    to,
                    length: data?.translationSentences?.length || 0,
                  })
                }
                nextIconButtonProps={{ disabled: !hasMoreResultsTranslations }}
              />
            </Grid>
          </Grid>
          <Grid
            item
            md={3}
            style={{
              display: 'flex',
              flexDirection: 'column',
              paddingLeft: '10px',
              gap: '10px',
              justifyContent: 'space-between',
            }}
          >
            <Grid
              item
              style={{
                display: 'flex',
                justifyContent: 'center',
              }}
            >
              <AnimationView
                isPlaying={isPlaying !== null}
                setIsPlaying={() => {}}
                onFinalizeAnimation={() => {
                  handleFinalizeAnimation()
                }}
                htaAnimations={currentHtaAnimations}
                rest={true}
                repeat={false}
                setSpeed={() => {}}
                speed={1}
                textFast={''}
                textLow={''}
                textMedium={''}
                textRotate={textRotateAvatar}
                hiddenControls={true}
                signs={currentSignsToShow}
                handleClickOpenSigns={handleClickOpenSigns}
                openTable={openTable}
                textNoSigns={textNoSigns}
                textAnimation={textAnimation}
                textReport={textReport}
                textPlayAnimation={textPlayAnimation}
                handleClickPlaySign={handleClickPlaySign}
                handleClickReportProblem={handleClickReportProblem}
                downTable={true}
                autoPlay={autoPlay}
              />
              {reportProblem && (
                <ReportModal
                  workspaceUsers={workspaceUsers}
                  otherProblem={otherProblem}
                  setOtherProblem={setOtherProblem}
                  openModalReport={reportProblem}
                  setOpenModalReport={setReportProblem}
                  setReportProblem={setReportProblem}
                  signId={signId}
                />
              )}
              <Typography style={{ position: 'absolute', color: 'black' }}>
                {timeText}: {animationTime} s
              </Typography>
            </Grid>
            <Grid
              item
              style={{
                display: 'flex',
                justifyContent: 'center',
                flexDirection: 'column',
                visibility: openTable ? 'hidden' : 'visible',
                position: openTable ? 'absolute' : 'relative',
              }}
            >
              <Paper
                style={{
                  width: '100%',
                  display: 'flex',
                  gap: '10px',
                  flexDirection: 'column',
                  height: `${window.innerHeight * 0.34}px`,
                }}
              >
                <SearchFieldSign
                  text={searchText}
                  onTextChange={handleSearchTextChange}
                  onKeyUpSearch={handleKeyUpSearch}
                  textSearch={textSearch}
                  onClickSearch={handleSearchSigns}
                  nmtSearch={nmtSearch}
                  onChangeUseNmtCheck={handleChangeUseNmtCheck}
                />
                <SearchSign
                  items={signs}
                  onPlayAnimation={handlePlayAnimation}
                  isPlaying={isPlaying}
                  onStopAnimation={handleFinalizeAnimation}
                />

                <TablePagination
                  style={{
                    pointerEvents: 'auto',
                    overflow: 'hidden',
                    minHeight: '50px',
                  }}
                  component="div"
                  count={-1}
                  rowsPerPage={paginationConfig.rowsPerPage}
                  page={paginationConfig.currentPage}
                  onPageChange={handleChangePage}
                  rowsPerPageOptions={[10]}
                  labelDisplayedRows={() => ''}
                  nextIconButtonProps={{ disabled: !hasMoreResults }}
                />
              </Paper>
            </Grid>
          </Grid>
        </Grid>
        <DragOverlay>{activeData ? <Chip label={activeData.word} /> : null}</DragOverlay>
      </DndContext>

      <ConfirmationDialog
        open={opennedDeleteDialog}
        toggleOpen={() => {
          setSelectedSentence(null)
          toggleOpenDeleteDialog()
        }}
        handleYes={handleConfirmDeleteSentence}
        title={intl.get('messages.confirmDeleteSentence')}
        subtitle={''}
      />
      <ConfirmationDialog
        open={opennedRejectProject}
        toggleOpen={() => {
          setReasonText('')
          toggleOpennedRejectProjectDialog()
        }}
        handleYes={() => {
          toggleOpennedRejectProjectDialog()
          handleRejectRequest(
            data?.uid || '',
            data?.ref || null,
            data?.interpreter || [],
            data?.errors || [],
            data?.projectName || '',
          )
        }}
        title={intl.get('pages.manuallyTranslation.confirmTheRejectionOfTheProject')}
        subtitle={''}
      >
        <TextField
          variant={'outlined'}
          value={reasonText}
          onChange={(ev) => {
            setReasonText(ev.target.value)
          }}
          label={intl.get('messages.reason')}
          fullWidth={true}
        />
      </ConfirmationDialog>

      <ConfirmationDialog
        open={opennedTextEdit}
        toggleOpen={() => {
          setTextEdit('')
          toggleOpennedTextEdit()
        }}
        handleYes={() => {
          toggleOpennedTextEdit()
          handleSaveEditedText()
        }}
        title={intl.get('messages.editText')}
        subtitle={''}
        disableKeyUp={true}
        maxWidth={'lg'}
      >
        <TextField
          multiline
          variant={'outlined'}
          value={textEdit}
          onChange={(ev) => {
            setTextEdit(ev.target.value)
          }}
          label={''}
          fullWidth
        />
      </ConfirmationDialog>
      <SignWarningDialog
        open={openSignWarning}
        setOpen={setOpenSignWarning}
        signs={signsWarning}
        setSigns={setSignsWarning}
      />
    </Layout>
  )
}

export default Translate
