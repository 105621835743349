import { Toolbar, Box, InputLabel, TextField, Select, MenuItem, Tooltip, alpha } from '@material-ui/core'
import SearchIcon from '@material-ui/icons/Search'
import ClearFilter from '@material-ui/icons/Clear'
import { ChangeEvent } from 'react'
import { IFilterObject, IFilterText } from '..'
import styled from 'styled-components'
import { IUser } from 'collections'

interface IFilterComponent {
  fsUser: IUser
  handleChangeSearchText: (
    event: ChangeEvent<{
      value: unknown
    }>,
  ) => void
  handleChangeSearchOrigin: (
    event: ChangeEvent<{
      value: unknown
    }>,
  ) => void
  handleChangeSearchCategory: (
    event: ChangeEvent<{
      value: unknown
    }>,
  ) => void

  handleChangeSearchCorpus: (
    event: ChangeEvent<{
      value: unknown
    }>,
  ) => void
  handleChangeSearchIsValidated: (
    event: ChangeEvent<{
      value: unknown
    }>,
  ) => void
  handleChangeSearchNeedResearch: (
    event: ChangeEvent<{
      value: unknown
    }>,
  ) => void
  handleChangeClientId: (
    event: ChangeEvent<{
      value: unknown
    }>,
  ) => void
  handleChangeSearchIsExternal: (
    event: ChangeEvent<{
      value: unknown
    }>,
  ) => void
  origins: string[]
  categories: string[]
  filterObject: IFilterObject
  handleSearch: () => void
  handleOnClearFilter: () => void
  filterTexts: IFilterText
}

const BackgroundFilled = styled('div')(({ theme }) => ({
  position: 'relative',
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  '&:hover': {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginLeft: 0,
  width: '100%',
  justifyContent: 'space-between',
  [theme.breakpoints.up('sm')]: {
    marginLeft: theme.spacing(1),
    width: 'auto',
  },
  display: 'flex',
  flexDirection: 'row',
}))

const IconWrapper = styled('div')(({ theme }) => ({
  padding: theme.spacing(1, 1, 1, 1),
  height: '100%',
  pointerEvents: 'none',
  display: 'flex',
  left: '1',
  alignItems: 'center',
  justifyContent: 'left',
}))

const FilterComponent: React.FC<IFilterComponent> = ({
  fsUser,
  handleChangeSearchText,
  handleChangeSearchOrigin,
  handleChangeSearchCategory,
  handleChangeSearchCorpus,
  handleChangeSearchIsValidated,
  handleChangeSearchNeedResearch,
  handleChangeClientId,
  handleChangeSearchIsExternal,
  origins,
  categories,
  handleSearch,
  filterObject,
  handleOnClearFilter,
  filterTexts,
}) => {
  return (
    <Toolbar style={{ justifyContent: 'space-between', paddingLeft: '0' }}>
      <Box
        style={{
          display: 'flex',
          flexDirection: 'row',
        }}
      >
        <Box style={{ marginLeft: '5px' }}>
          <InputLabel id="x-label" style={{ minWidth: '5em', maxWidth: '10em', color: 'inherit' }}>
            {filterTexts.searchText}
          </InputLabel>
          <TextField
            fullWidth
            id="standard-basic"
            variant="standard"
            value={filterObject.searchText}
            onChange={handleChangeSearchText}
          />
        </Box>
        <Box style={{ marginLeft: '5px' }}>
          <InputLabel id="x-label" style={{ minWidth: '5em', maxWidth: '10em', color: 'inherit' }}>
            {filterTexts.origins}
          </InputLabel>
          <Select
            fullWidth
            id="grouped-select"
            style={{ color: 'inherit' }}
            onChange={handleChangeSearchOrigin}
            value={filterObject.searchSentenceOrigin.toUpperCase()}
            MenuProps={{
              anchorOrigin: {
                vertical: 'bottom',
                horizontal: 'left',
              },
              transformOrigin: {
                vertical: 'top',
                horizontal: 'left',
              },
              getContentAnchorEl: null,
            }}
          >
            <MenuItem key={''} value={''}>
              {filterTexts.removeFilter}
            </MenuItem>
            {origins &&
              origins.map((origin, key) => (
                <MenuItem key={key} value={origin}>
                  {origin}
                </MenuItem>
              ))}
          </Select>
        </Box>
        <Box style={{ marginLeft: '5px' }}>
          <InputLabel id="x-label" style={{ minWidth: '5em', maxWidth: '10em', color: 'inherit' }}>
            {filterTexts.categories}
          </InputLabel>
          <Select
            fullWidth
            id="grouped-select"
            style={{ color: 'inherit' }}
            onChange={handleChangeSearchCategory}
            value={filterObject.searchCategory}
            MenuProps={{
              anchorOrigin: {
                vertical: 'bottom',
                horizontal: 'left',
              },
              transformOrigin: {
                vertical: 'top',
                horizontal: 'left',
              },
              getContentAnchorEl: null,
            }}
          >
            <MenuItem key={''} value={''}>
              {filterTexts.removeFilter}
            </MenuItem>
            {categories.map((category, key) => (
              <MenuItem key={key} value={category}>
                {category}
              </MenuItem>
            ))}
          </Select>
        </Box>
        <Box
          style={{
            marginLeft: '5px',
          }}
        >
          <InputLabel
            id="x-label"
            style={{
              minWidth: '5em',
              maxWidth: '10em',
              color: 'inherit',
            }}
          >
            {filterTexts.corpus}
          </InputLabel>
          <Select
            fullWidth
            id="grouped-select"
            style={{ color: 'inherit' }}
            onChange={handleChangeSearchCorpus}
            value={filterObject.searchCorpusGroup}
            MenuProps={{
              anchorOrigin: {
                vertical: 'bottom',
                horizontal: 'left',
              },
              transformOrigin: {
                vertical: 'top',
                horizontal: 'left',
              },
              getContentAnchorEl: null,
            }}
          >
            <MenuItem key={''} value={''}>
              {filterTexts.removeFilter}
            </MenuItem>
            <MenuItem key="0" value={'TRAIN'}>
              {filterTexts.train}
            </MenuItem>
            <MenuItem key="1" value={'VALIDATION'}>
              {filterTexts.validation}
            </MenuItem>
          </Select>
        </Box>
        <Box style={{ marginLeft: '5px' }}>
          <InputLabel
            id="x-label"
            style={{
              minWidth: '5em',
              maxWidth: '10em',
              color: 'inherit',
            }}
          >
            {filterTexts.validated}
          </InputLabel>
          <Select
            fullWidth
            id="grouped-select"
            style={{ color: 'inherit' }}
            onChange={handleChangeSearchIsValidated}
            value={filterObject.searchIsValidated}
            MenuProps={{
              anchorOrigin: {
                vertical: 'bottom',
                horizontal: 'left',
              },
              transformOrigin: {
                vertical: 'top',
                horizontal: 'left',
              },
              getContentAnchorEl: null,
            }}
          >
            <MenuItem key={''} value={''}>
              {filterTexts.removeFilter}
            </MenuItem>
            <MenuItem key="0" value={'true'}>
              {filterTexts.yes}
            </MenuItem>
            <MenuItem key="1" value={'false'}>
              {filterTexts.no}
            </MenuItem>
          </Select>
        </Box>
        <Box style={{ marginLeft: '5px' }}>
          <InputLabel
            id="x-label"
            style={{
              minWidth: '5em',
              maxWidth: '10em',
              color: 'inherit',
            }}
          >
            {filterTexts.needResearch}
          </InputLabel>
          <Select
            fullWidth
            id="grouped-select"
            style={{ color: 'inherit' }}
            onChange={handleChangeSearchNeedResearch}
            value={filterObject.searchNeedResearch}
            MenuProps={{
              anchorOrigin: {
                vertical: 'bottom',
                horizontal: 'left',
              },
              transformOrigin: {
                vertical: 'top',
                horizontal: 'left',
              },
              getContentAnchorEl: null,
            }}
          >
            <MenuItem key={''} value={''}>
              {filterTexts.removeFilter}
            </MenuItem>
            <MenuItem key="0" value={'true'}>
              {filterTexts.yes}
            </MenuItem>
            <MenuItem key="1" value={'false'}>
              {filterTexts.no}
            </MenuItem>
          </Select>
        </Box>
        <Box style={{ marginLeft: '5px' }}>
          <InputLabel id="x-label" style={{ minWidth: '5em', maxWidth: '10em', color: 'inherit' }}>
            {filterTexts.clientId}
          </InputLabel>
          <TextField
            fullWidth
            id="standard-basic"
            variant="standard"
            value={filterObject.searchClientId}
            onChange={handleChangeClientId}
          />
        </Box>
        {!fsUser.isExternal && (
          <Box style={{ marginLeft: '5px' }}>
            <InputLabel
              id="x-label"
              style={{
                minWidth: '5em',
                maxWidth: '10em',
                color: 'inherit',
              }}
            >
              {filterTexts.external}
            </InputLabel>

            <Select
              fullWidth
              id="grouped-select"
              style={{ color: 'inherit' }}
              onChange={handleChangeSearchIsExternal}
              value={filterObject.searchIsExternal}
              MenuProps={{
                anchorOrigin: {
                  vertical: 'bottom',
                  horizontal: 'left',
                },
                transformOrigin: {
                  vertical: 'top',
                  horizontal: 'left',
                },
                getContentAnchorEl: null,
              }}
            >
              <MenuItem key={''} value={''}>
                {filterTexts.removeFilter}
              </MenuItem>
              <MenuItem key="0" value={'true'}>
                {filterTexts.yes}
              </MenuItem>
              <MenuItem key="1" value={'false'}>
                {filterTexts.no}
              </MenuItem>
            </Select>
          </Box>
        )}
        <Box style={{ display: 'flex', flexDirection: 'row' }}>
          <Tooltip
            title={filterTexts.clearFilter}
            style={{
              alignSelf: 'center',
              cursor: 'pointer',
            }}
            onClick={handleOnClearFilter}
          >
            <BackgroundFilled>
              <IconWrapper>
                <ClearFilter />
              </IconWrapper>
            </BackgroundFilled>
          </Tooltip>

          <Tooltip
            title={filterTexts.search}
            style={{
              alignSelf: 'center',
              cursor: 'pointer',
            }}
          >
            <BackgroundFilled onClick={handleSearch}>
              <IconWrapper>
                <SearchIcon />
              </IconWrapper>
            </BackgroundFilled>
          </Tooltip>
        </Box>
      </Box>
    </Toolbar>
  )
}

export default FilterComponent
