import React, { useEffect, useState } from 'react'
import styled from 'styled-components'

import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import Card from '@material-ui/core/Card'
import Box from '@material-ui/core/Box'
import { AccordionDetails, AccordionSummary, Accordion, ButtonGroup, Button } from '@material-ui/core'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'

import VideoCard from '../VideoCard'
import Preloader from '../../../components/Preloader'
import Layout from '../../../components/Layout'
import useContributionsPagination from '../../../services/hooks/useContributionsPagination'
import ContributionsHeader from './ContributionsHeader'
import useIntl from '../../../hooks/useIntl'
import { useFsUserDocData } from '../../../hooks/useFsUser'
import { useDatabase, useDatabaseObjectData, useUser } from 'reactfire'
import { ref } from 'firebase/database'
import SegmentVideo from '../../SegmentVideo/Video'
import { DocumentReference, IOptionsSelect } from 'collections'

const Container = styled.div`
  min-width: 600px;
  width: 40%;
  margin: 0 auto;
  margin-top: 30px;

  @media (max-width: 600px) {
    min-width: unset;
    width: 100%;
    margin-top: unset;
  }
`

const BlockGrid = styled(Grid)`
  display: flex;
  height: 300px;
  align-items: center;
  justify-content: center;
`

interface IContributionsProps {
  path?: string
}
/**
 * Página de renderização de vídeos, aqui exibiremos o conteúdo de vídeos para os interpretes
 */
const Contributions: React.FC<IContributionsProps> = () => {
  const [{ videos, isApplyingFilters, filters, hasMoreResults }, { setCommunityModule, applyFilters }] =
    useContributionsPagination()
  const [origins, setOrigins] = useState<string[]>([])
  const [categories, setCategories] = useState<string[]>([])
  const [isSegmenting, setIsSegmenting] = useState(false)
  const [videoToEditSegment, setVideoToEditSegment] = useState({
    videoId: '',
    userId: '',
  })
  const [isMaintainer, setIsMaintener] = useState(false)
  const [isLoadingVideos, setIsLoadingVideos] = useState(true)
  const [currentPage, setCurrentPage] = useState(filters.page)
  const fbUser = useUser<firebase.default.User>()
  const fsUser = useFsUserDocData()
  const database = useDatabase()
  const intl = useIntl()

  const originsReferenceString = `${fsUser.workspace.id}/${fsUser.oralLanguageId}/${fsUser.signLanguageId}/sentenceOrigins`
  const originsRef = ref(database, originsReferenceString)
  const { status: statusOrigins, data: originsData } = useDatabaseObjectData(originsRef)

  const optionsStateVideo: IOptionsSelect[] = [
    { label: intl.get('pages.processVideo.none'), value: 'NONE' },
    { label: intl.get('pages.processVideo.validated'), value: 'VALIDATED' },
    { label: intl.get('pages.processVideo.processed'), value: 'PROCESSED' },
    { label: intl.get('pages.processVideo.segmented'), value: 'SEGMENTED' },
    {
      label: intl.get('pages.processVideo.noSegmented'),
      value: 'EXTRACTED_FRAMES',
    },
    {
      label: intl.get('messages.missingSign'),
      value: 'MISSING_SIGN',
    },
    {
      label: intl.get('messages.quarantine'),
      value: 'QUARANTINE',
    },
  ]

  const getOrigins = async () => {
    if (statusOrigins == 'error') return
    if (!originsData) return
    const originsObj = originsData as { id: number; origin: string }
    const origins: string[] = []
    const originsArray = Object.entries(originsObj)
    originsArray.map((value, index) => {
      if (originsArray.length - 1 == index) return
      const origin = value as string[]
      origins.push(origin[1])
    })
    setOrigins(origins)
  }

  const getCategories = async () => {
    const _categories: string[] = intl
      .get('sentenceCategories')
      .split(',')
      .map((el) => {
        return el.trim()
      })

    setCategories(_categories)
  }

  const handleChangePage = (direction: string) => {
    let newPage = currentPage
    if (direction === 'next') {
      newPage = currentPage! + 1
      setCurrentPage(newPage)
    } else {
      newPage = currentPage! - 1
      setCurrentPage(newPage)
    }
    applyFilters(
      filters.startDate,
      filters.endDate,
      filters.userReference,
      filters.searchText,
      filters.signId,
      filters.sentenceOrigin,
      filters.sentenceCategory,
      filters.videoState,
      newPage,
    )
  }

  const handleApplyHeaderFilters = (
    startDate: Date,
    endDate: Date,
    userReference?: DocumentReference,
    searchText?: string,
    signId?: string,
    sentenceOrigin?: string,
    sentenceCategory?: string,
    videoState?: string,
    page?: number,
  ) => {
    setCurrentPage(page)
    applyFilters(
      startDate,
      endDate,
      userReference,
      searchText,
      signId,
      sentenceOrigin,
      sentenceCategory,
      videoState,
      page,
    )
  }

  useEffect(() => {
    setCommunityModule('videoManager', false)
    getOrigins()
    getCategories()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (videos.length > 0) {
      setIsLoadingVideos(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [videos])

  useEffect(() => {
    const call = async () => {
      const tokenResult = await fbUser.data?.getIdTokenResult()
      const _isMaintainer: boolean = !!tokenResult?.claims?.isMaintainer

      setIsMaintener(_isMaintainer)
    }
    call()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const content = (
    <>
      {isLoadingVideos ? (
        <BlockGrid item sm={12} lg={12}>
          <Preloader asBlock text="Loading vídeos..." />
        </BlockGrid>
      ) : (
        <>
          {videos.length == 0 && (
            <BlockGrid item sm={12} lg={12}>
              <Typography style={{ margin: '45px' }}>Não há vídeos a serem exibidos.</Typography>
            </BlockGrid>
          )}
          {videos.length > 0 && (
            <Grid container spacing={2} style={{ width: '100%', margin: 0 }}>
              {videos.map(
                ({
                  _state,
                  clientId,
                  corpusGroup,
                  errors,
                  needCreateSign,
                  numberOfSegmentations,
                  segmentedBy,
                  segmentsToProcess,
                  sentenceCategory,
                  createdOnDemand,
                  numberOfSegments,
                  sentenceOrigin,
                  signs,
                  sentence,
                  videoId,
                  videoSrc,
                  displayName,
                  createdAt,
                  createdBy,
                  usedOnTrain,
                }) => (
                  <Grid key={videoId} item sm={12} lg={12}>
                    <Accordion>
                      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                        <Typography>{sentence}</Typography>
                      </AccordionSummary>

                      <AccordionDetails>
                        <VideoCard
                          key={videoId}
                          sentence={sentence}
                          _state={_state}
                          clientId={clientId}
                          corpusGroup={corpusGroup}
                          errors={errors}
                          needCreateSign={needCreateSign}
                          numberOfSegmentations={numberOfSegmentations}
                          segmentedBy={segmentedBy}
                          segmentsToProcess={segmentsToProcess}
                          numberOfSegments={numberOfSegments}
                          sentenceCategory={sentenceCategory}
                          sentenceOrigin={sentenceOrigin}
                          signs={signs}
                          createdOnDemand={createdOnDemand}
                          displayName={displayName}
                          videoSrc={videoSrc}
                          createdAt={createdAt}
                          createdBy={createdBy}
                          videoId={videoId}
                          usedOnTrain={usedOnTrain}
                          categories={categories}
                          origins={origins}
                          isMaintainer={isMaintainer}
                          handleOpenEditSegment={(videoId, user) => {
                            setVideoToEditSegment({
                              videoId: videoId,
                              userId: user,
                            })
                            setIsSegmenting(true)
                          }}
                        />
                      </AccordionDetails>
                    </Accordion>
                  </Grid>
                ),
              )}
            </Grid>
          )}
        </>
      )}
    </>
  )

  const loader = (
    <BlockGrid item sm={12} lg={12} style={{ minHeight: '700px' }}>
      <Preloader asBlock text="Applying filters" />
    </BlockGrid>
  )

  return (
    <>
      {!isSegmenting && (
        <Layout title={intl.get('modules.videoManager')} imageBg id="scrollable">
          <Container>
            <Card
              style={{
                display: 'flex',
                flexDirection: 'column',
                marginBottom: '10px',
              }}
            >
              <ContributionsHeader
                categories={categories}
                origins={origins}
                videoStates={optionsStateVideo}
                applyFiltersProps={handleApplyHeaderFilters}
              />
              <Box
                style={{
                  padding: '16px',
                  display: 'flex',
                  flexDirection: 'column',
                }}
              >
                {!isApplyingFilters ? content : loader}
              </Box>
              {!isApplyingFilters && !isLoadingVideos && videos.length > 0 && (
                <ButtonGroup disableElevation variant="outlined" color="primary" style={{ margin: '0px auto 20px' }}>
                  <Button
                    disabled={currentPage == 0}
                    style={{ textTransform: 'capitalize', fontSize: '1rem' }}
                    onClick={() => handleChangePage('previous')}
                  >
                    {intl.get('pages.videoManager.others.previousPage')}
                  </Button>
                  <Button
                    disabled={!hasMoreResults}
                    style={{ textTransform: 'capitalize', fontSize: '1rem' }}
                    onClick={() => handleChangePage('next')}
                  >
                    {intl.get('pages.videoManager.others.nextPage')}
                  </Button>
                </ButtonGroup>
              )}
            </Card>
          </Container>
        </Layout>
      )}
      {isSegmenting && (
        <SegmentVideo
          videoIdParam={videoToEditSegment.videoId}
          userIdParam={videoToEditSegment.userId}
          setIsSegmenting={setIsSegmenting}
        />
      )}
    </>
  )
}

export default Contributions
