import React, { ChangeEvent, useEffect, useState } from 'react'
import { createStyles, Theme, withStyles, WithStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import MuiDialogTitle from '@material-ui/core/DialogTitle'
import MuiDialogContent from '@material-ui/core/DialogContent'
import MuiDialogActions from '@material-ui/core/DialogActions'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'
import CloudUploadIcon from '@material-ui/icons/CloudUpload'
import AddCircleIcon from '@material-ui/icons/AddCircle'
import DeleteIcon from '@material-ui/icons/Delete'
import {
  Box,
  Fab,
  FormControl,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  MenuItem,
  Select,
  styled,
  TextField,
  Tooltip,
  Typography,
} from '@material-ui/core'
import AddIcon from '@material-ui/icons/Add'
import useIntl from '../../../hooks/useIntl'
import { ISentence } from '..'
import { Database, ref, get, set } from '@firebase/database'
import { IUser, accentRemove, ISentenceData } from 'collections'
import useSnackbar from '../../../services/hooks/useSnackbar'
import { setPhraseToRecordVideoPriority } from '../../../services/phrases'

interface IModalIncludePhrase {
  sentences: ISentence[]
  setSentences: React.Dispatch<React.SetStateAction<ISentence[]>>
  handleCloseModal: () => void
  database: Database
  fsUser: IUser
  origins: string[]
}

const styles = (theme: Theme) =>
  createStyles({
    root: {
      margin: 0,
      padding: theme.spacing(2),
    },
    closeButton: {
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
    },
  })

const Input = styled('input')({
  display: 'none',
})

export interface DialogTitleProps extends WithStyles<typeof styles> {
  id: string
  children: React.ReactNode
  onClose: () => void
}

const DialogTitle = withStyles(styles)((props: DialogTitleProps) => {
  const { children, classes, onClose, ...other } = props
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  )
})

const DialogContent = withStyles((theme: Theme) => ({
  root: {
    padding: theme.spacing(2),
    minWidth: '500px',
  },
}))(MuiDialogContent)

const DialogActions = withStyles((theme: Theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions)

const defaultSentece = {
  id: '',
  sentence: '',
  sentenceCategory: ['undefined'],
  isValidated: false,
  corpusGroup: 'TRAIN',
  sentenceOrigin: 'INTERNAL',
  priority: 10,
  clientId: 'HT',
  isRecording: false,
  needResearch: false,
  isRepeated: false,
  userRecording: '',
  isExternal: false,
}

const ModalIncludePhrase: React.FC<IModalIncludePhrase> = ({
  sentences,
  setSentences,
  handleCloseModal,
  database,
  fsUser,
  origins,
}) => {
  const [open, setOpen] = useState(false)
  const [sentenceOriginToInclude, setSentenceOriginToInclude] = useState('')
  const [sentencesToInclude, setSentencesToInclude] = useState<ISentence[] | undefined>(undefined)
  const [clientId, setClientId] = useState<string>('HT')
  const [sentencesInfo, setSentencesInfo] = useState<{
    corpus: string
    sentenceOrigin: string
    isExternal: boolean
  }>({ corpus: 'TRAIN', sentenceOrigin: 'INTERNAL', isExternal: false })
  const [textToAdd, setTextToAdd] = useState('')
  const intl = useIntl()
  const showSnackbar = useSnackbar()

  const handleClickOpen = () => {
    setOpen(true)
  }
  const handleClose = () => {
    setOpen(false)
    handleCloseModal()
    setSentencesToInclude(undefined)
  }

  useEffect(() => {
    if (open) {
      setClientId('HT')
      setSentencesInfo({
        corpus: 'TRAIN',
        sentenceOrigin: 'INTERNAL',
        isExternal: false,
      })
    }
  }, [open])

  const handleSavePhrase = async () => {
    try {
      const _sentences = [...sentences]
      sentencesToInclude?.map((sentenceToInclude) => {
        const newSentence: ISentenceData = {
          isValidated: false,
          sentence: sentenceToInclude.sentence,
          sentenceCategory: ['undefined'],
          sentenceOrigin: accentRemove(sentencesInfo.sentenceOrigin.toUpperCase() || 'INTERNAL'),
          corpusGroup: sentencesInfo.corpus || 'TRAIN',
          priority: sentenceToInclude.priority || 10,
          clientId: clientId || 'HT',
          isRecording: false,
          needResearch: false,
          isRepeated: false,
          userRecording: '',
          isExternal: sentencesInfo.isExternal || false,
        }

        const newSentenceId = setPhraseToRecordVideoPriority(database, fsUser, newSentence)

        _sentences.push({
          id: newSentenceId || '',
          ...newSentence,
        })
      })
      setSentences(_sentences)

      showSnackbar(intl.get('messages.savedSuccessfully'), {
        variant: 'success',
      })
    } catch (error) {
      showSnackbar((error as TypeError | RangeError | EvalError).message, {
        variant: 'error',
      })
    }
  }

  const handleUploadFile = (input: HTMLInputElement) => {
    const fileInput = input
    try {
      fileInput.onchange = async () => {
        try {
          // Checar se algum arquivo foi importado
          if (fileInput && fileInput.files != null) {
            // Resgata o arquivo que foi importado
            const selectedFile = fileInput.files[0]
            // Cria um objeto para decodificar o buffer
            const enc = new TextDecoder('utf-8')
            let textOfFile: string[] = []
            /* Tratamento do arquivo
              - resgatando todos os caracteres do arquivo
              - converte para utf-8
              - separa cada linha pelo enter
            */
            await selectedFile.arrayBuffer().then((value) => {
              textOfFile = enc.decode(value.slice(0, value.byteLength)).split('\n')
            })
            const _sentences: ISentence[] = []
            textOfFile.map((line) => {
              if (!line) return
              _sentences.push({
                ...defaultSentece,
                sentence: line.normalize('NFKC').trim(),
              })
            })
            setSentencesToInclude(_sentences)
          }
        } catch (error) {
          showSnackbar((error as TypeError | RangeError | EvalError).message, {
            variant: 'error',
          })
        }
      }
    } finally {
      fileInput.value = ''
    }
  }

  const handleOnChangeSentenceOriginToInclude = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSentenceOriginToInclude(accentRemove(event.target.value.toUpperCase()))
  }

  const handleAddNewOrigin = async () => {
    if (!sentenceOriginToInclude) {
      showSnackbar(intl.get('messages.emptySentenceOrigin'), {
        variant: 'warning',
      })
      return
    }
    // Rotina para atualizar coleção de origens
    const sentenceOriginsRef = ref(
      database,
      `${fsUser.workspace.id}/${fsUser.oralLanguageId}/${fsUser.signLanguageId}/sentenceOrigins`,
    )
    const sentenceOriginSnapshot = await get(sentenceOriginsRef)
    const _sentenceValues = sentenceOriginSnapshot.val() as string[]
    const sentenceValues: string[] = []
    _sentenceValues.forEach((el) => {
      if (el) sentenceValues.push(el)
    })

    if (sentenceOriginSnapshot.val() != null) {
      const sentenceOriginData = sentenceOriginSnapshot.val()
      const finded = sentenceOriginData.find((el: string) => el == sentenceOriginToInclude)
      if (!finded) {
        sentenceValues.push(sentenceOriginToInclude)
        await set(sentenceOriginsRef, [...sentenceValues])
        showSnackbar(intl.get('messages.originSaved'), {
          variant: 'success',
        })
      } else
        showSnackbar(intl.get('messages.originExists'), {
          variant: 'info',
        })
    }
    setSentenceOriginToInclude('')
  }

  const handleAddToSentencesToInclude = () => {
    if (!textToAdd.trim()) return
    const textPhrases = textToAdd.split('\n')
    const _sentencesToInclude = sentencesToInclude || []
    textPhrases.forEach((el) => _sentencesToInclude?.push({ ...defaultSentece, sentence: el.trim() }))
    setSentencesToInclude(_sentencesToInclude)
    setTextToAdd('')
  }

  const handleDeleteFromSentencesToInclude = (sentence: string) => {
    const _sentencesToInclude: ISentence[] = []
    sentencesToInclude?.map((el) => {
      _sentencesToInclude.push(el)
    })
    const idx = _sentencesToInclude?.findIndex((el) => el.sentence == sentence) || -1
    _sentencesToInclude?.splice(idx, 1)
    setSentencesToInclude(_sentencesToInclude)
  }

  return (
    <>
      <Fab
        aria-label="Add"
        style={{
          position: 'fixed',
          bottom: '10px',
          right: '30px',
        }}
        color="primary"
        onClick={handleClickOpen}
      >
        <AddIcon />
      </Fab>
      <Dialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        maxWidth={'md'}
        fullWidth={true}
      >
        <DialogTitle id="customized-dialog-title" onClose={handleClose}>
          {intl.get('pages.phraseManager.addNewSentence')}
        </DialogTitle>
        <DialogContent dividers style={{ display: 'flex', flexDirection: 'column' }}>
          <Box style={{ display: 'flex', flexDirection: 'row' }}>
            <TextField
              value={textToAdd}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => setTextToAdd(event.target.value)}
              label={intl.get('pages.phraseManager.phrase')}
              variant="filled"
              style={{ marginTop: '10px' }}
              maxRows={10}
              fullWidth
              multiline
            />
            <IconButton
              onClick={() => handleAddToSentencesToInclude()}
              style={{ marginTop: '10px' }}
              aria-label="add-text"
            >
              <AddCircleIcon />
            </IconButton>
          </Box>
          <List
            style={{
              width: '100%',
              backgroundColor: 'background.paper',
              position: 'relative',
              overflow: 'auto',
              maxHeight: 300,
            }}
          >
            {sentencesToInclude?.map((sentenceToInclude: ISentence, index: number) => (
              <ListItem key={index} divider>
                <ListItemText primary={sentenceToInclude.sentence} />
                <ListItemSecondaryAction>
                  <IconButton
                    onClick={() => handleDeleteFromSentencesToInclude(sentenceToInclude.sentence)}
                    edge="end"
                    aria-label="delete"
                  >
                    <DeleteIcon />
                  </IconButton>
                </ListItemSecondaryAction>
              </ListItem>
            ))}
          </List>
          <Box>
            <TextField
              value={clientId}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => setClientId(event.target.value)}
              label={intl.get('messages.clientId')}
              variant="filled"
              style={{ marginTop: '10px' }}
              fullWidth
            />
          </Box>
          <Box
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between',
            }}
          >
            <Box
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
              }}
            >
              <FormControl style={{ marginTop: '10px', width: '50%' }}>
                <Typography id="x-label">Corpus</Typography>
                <Select
                  id="grouped-select"
                  style={{ color: 'inherit' }}
                  onChange={(
                    event: ChangeEvent<{
                      value: unknown
                    }>,
                  ) => {
                    const corpusGroup = event.target.value as string
                    setSentencesInfo({ ...sentencesInfo, corpus: corpusGroup })
                  }}
                  defaultValue="TRAIN"
                  value={sentencesInfo.corpus}
                  MenuProps={{
                    anchorOrigin: {
                      vertical: 'bottom',
                      horizontal: 'left',
                    },
                    transformOrigin: {
                      vertical: 'top',
                      horizontal: 'left',
                    },
                    getContentAnchorEl: null,
                  }}
                >
                  <MenuItem key="0" value={'TRAIN'}>
                    {intl.get('pages.signOnDemand.corpusGroup.train')}
                  </MenuItem>
                  <MenuItem key="1" value={'VALIDATION'}>
                    {intl.get('pages.signOnDemand.corpusGroup.validation')}
                  </MenuItem>
                </Select>
              </FormControl>
              {!fsUser.isExternal && (
                <Box
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    width: '45%',
                  }}
                >
                  <FormControl style={{ marginTop: '10px' }}>
                    <Typography id="x-label">{intl.get('pages.phraseManager.filters.external')}</Typography>
                    <Select
                      fullWidth
                      id="grouped-select"
                      style={{ color: 'inherit' }}
                      onChange={(
                        event: ChangeEvent<{
                          value: unknown
                        }>,
                      ) => {
                        const _isExternal = event.target.value as string
                        setSentencesInfo({
                          ...sentencesInfo,
                          isExternal: _isExternal == 'true' ? true : false,
                        })
                      }}
                      value={sentencesInfo.isExternal}
                      MenuProps={{
                        anchorOrigin: {
                          vertical: 'bottom',
                          horizontal: 'left',
                        },
                        transformOrigin: {
                          vertical: 'top',
                          horizontal: 'left',
                        },
                        getContentAnchorEl: null,
                      }}
                    >
                      <MenuItem key={''} value={''}>
                        {intl.get('pages.phraseManager.filters.removeFilter')}
                      </MenuItem>
                      <MenuItem key="0" value={'true'}>
                        {intl.get('messages.yes')}
                      </MenuItem>
                      <MenuItem key="1" value={'false'}>
                        {intl.get('messages.no')}
                      </MenuItem>
                    </Select>
                  </FormControl>
                </Box>
              )}
            </Box>
            <FormControl
              style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
                marginTop: '10px',
              }}
            >
              <Typography id="x-label">{intl.get('pages.phraseManager.sentenceOrigin')}</Typography>
              <Box
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                }}
              >
                <Select
                  id="grouped-select"
                  style={{ color: 'inherit' }}
                  defaultValue="INTERNAL"
                  fullWidth
                  value={sentencesInfo.sentenceOrigin}
                  onChange={(
                    event: ChangeEvent<{
                      value: unknown
                    }>,
                  ) => {
                    const sentenceOrigin = event.target.value as string
                    setSentencesInfo({ ...sentencesInfo, sentenceOrigin })
                  }}
                  MenuProps={{
                    anchorOrigin: {
                      vertical: 'bottom',
                      horizontal: 'left',
                    },
                    transformOrigin: {
                      vertical: 'top',
                      horizontal: 'left',
                    },
                    getContentAnchorEl: null,
                  }}
                >
                  {origins &&
                    origins.map((origin) => (
                      <MenuItem key="0" value={origin}>
                        {origin}
                      </MenuItem>
                    ))}
                </Select>

                <TextField
                  placeholder={intl.get('pages.phraseManager.includeOrigin')}
                  onChange={handleOnChangeSentenceOriginToInclude}
                  value={sentenceOriginToInclude}
                  multiline
                  fullWidth
                  style={{
                    marginLeft: '10px',
                    marginTop: '30px',
                    width: '50%',
                  }}
                ></TextField>
                <Tooltip title={intl.get('pages.phraseManager.saveOrigin')}>
                  <IconButton onClick={() => handleAddNewOrigin()} style={{ marginTop: '10px' }} aria-label="add-text">
                    <AddCircleIcon />
                  </IconButton>
                </Tooltip>
              </Box>
            </FormControl>
          </Box>
        </DialogContent>
        <DialogActions style={{ justifyContent: 'right' }}>
          <Tooltip
            title={intl.get('pages.phraseManager.importFile')}
            onClick={() => {
              handleUploadFile(document.getElementById('icon-button-file') as HTMLInputElement)
            }}
          >
            <label htmlFor="icon-button-file">
              <Input accept="hts/*" id="icon-button-file" type="file" />
              <IconButton aria-label="upload file" component="span">
                <CloudUploadIcon style={{ cursor: 'pointer' }} fontSize="large" />
              </IconButton>
            </label>
          </Tooltip>

          <Button
            autoFocus
            color="primary"
            onClick={() => {
              if (!sentencesToInclude) {
                showSnackbar(intl.get('pages.phraseManager.warnings.informSentence'), {
                  variant: 'warning',
                })
                return
              }
              handleSavePhrase()
              handleClose()
            }}
          >
            {intl.get('messages.save')}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default ModalIncludePhrase
