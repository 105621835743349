import {
  TableContainer,
  TableBody,
  TableHead,
  TableRow,
  TableCell,
  Paper,
  Table,
  Grid,
  Box,
  TablePagination,
  Button,
  Typography,
  CircularProgress,
  Fade,
  FormControlLabel,
  Switch,
  makeStyles,
  IconButton,
  InputBase,
  Link,
  Tooltip,
} from '@material-ui/core'
import React, { useCallback, useEffect, useState } from 'react'
import styled, { css } from 'styled-components'
import Layout from '../../components/Layout'
import useIntl from '../../hooks/useIntl'
import SearchIcon from '@material-ui/icons/Search'
import SaveIcon from '@material-ui/icons/Save'
import CancelIcon from '@material-ui/icons/CancelOutlined'
import UndoIcon from '@material-ui/icons/Undo'
import {
  DocumentReference,
  getCollectionReference,
  IOptionsSelect,
  IPaginationConfig,
  ISegmentView,
  IVideo,
  IProcessVideoInput,
  getFirestoreDocument,
  ISign,
  WorkspaceId,
  generateSearchTermsVideo,
  updateVideo,
  serverTimestamp,
  Timestamp,
  getData,
  updateFirestoreDocument,
  ISignOnDemand,
  addFirestoreDocument,
  getDocumentReference,
  IUserInfo,
} from 'collections'
import Select from '../../components/Select'
import {
  query,
  orderBy,
  startAfter,
  endBefore,
  limitToLast,
  limit,
  DocumentData,
  Query,
  where,
  collection,
  documentId,
  getDocs,
} from '@firebase/firestore'
import { useFsUserDocData, useFsUserDocRef } from '../../hooks/useFsUser'
import VerticalTimeline from './VerticalTimeline'
import SignSearch from '../../components/SignSearch'
import { backVideoStateFunction, getAllUsersFunction, processVideoOnCallFunction } from '../../services/firebase'
import useSnackbar from '../../services/hooks/useSnackbar'
import ConfirmationDialog from '../../components/ConfirmationDialog'
import { useDatabase, useFirestoreCollection } from 'reactfire'
import { blue, green } from '@material-ui/core/colors'
import SegmentVideo from '../SegmentVideo/Video'
import { ISentenceData } from 'collections'
import { setPhraseToRecordVideoPriority } from '../../services/phrases'
import FabGroupButton from '../../components/FabGroupButton'
import useCheckHasPermission from '../../services/hooks/useCheckHasPermission'
import CardMessage from '../../components/CardMessage'
import { ref, runTransaction, get } from '@firebase/database'
import LaunchIcon from '@material-ui/icons/Launch'
import { routes } from '../../community'
import { IOriginData } from '../PhraseManager'
import ModalBackPhraseToRecording from './ModalBackPhraseToRecording'

interface ISignRowStyleProps {
  selectedVideo: boolean
  isProcessingVideo?: boolean
}

const SignCell = styled(TableCell)`
  cursor: pointer;
  font-weight: bold;
  ${({ selectedVideo, isProcessingVideo }: ISignRowStyleProps) =>
    isProcessingVideo &&
    !selectedVideo &&
    css`
      color: #c0c0c0;
    `}
`
export interface ISegmentViewProcessVideo extends ISegmentView {
  oldMovement?: DocumentReference | null
  oldText?: string
  oldType?: string
  framesVideo?: string[]
  videoDuration: number
  videoSentenceCategories?: string[] | null
  videoSentenceOrigin?: string
  id: string
}

const SignRow = styled(TableRow)`
  ${({ selectedVideo }: ISignRowStyleProps) =>
    selectedVideo &&
    css`
      background-color: #a0a0a0;
      weight: bold;
    `}
  ${({ selectedVideo }: ISignRowStyleProps) =>
    css`
      &:nth-child(odd) {
        background-color: ${selectedVideo ? '#a0a0a0' : '#c4c4c41a'};
      }
    `}
`

const useStyles = makeStyles(() => ({
  switch_track: {
    backgroundColor: '#c3c3c3',
  },
  switch_base: {
    color: '#c3c3c3',
    '&.Mui-disabled': {
      color: '#c3c3c3',
    },
    '&.Mui-checked': {
      color: blue[300],
    },
    '&.Mui-checked + .MuiSwitch-track': {
      backgroundColor: blue[500],
    },
  },
  switch_primary: {
    '&.Mui-checked': {
      color: blue[500],
    },
    '&.Mui-checked + .MuiSwitch-track': {
      backgroundColor: blue[500],
    },
  },
}))

interface IProcessVideoProps {
  path?: string
}

const initialPaginationConfig: IPaginationConfig = {
  currentPage: 0,
  firstOfList: null,
  lastOfList: null,
  load: true,
  rowsPerPage: 10,
}

export interface IVideoWithId extends IVideo {
  id: string
  ref: DocumentReference
}

export interface ISignWithRef {
  signData: ISign
  refSign: DocumentReference
  segmentView?: ISegmentView
}

const ProcessVideo: React.FC<IProcessVideoProps> = () => {
  const hasPermissionOnModule = useCheckHasPermission('processVideo')
  const [isLoading, setIsLoading] = useState(false)
  const [searchText, setSearchText] = useState('')
  const [sentenceCategory, setSentenceCategory] = useState('ALL')
  const [sentenceOrigin, setSentenceOrigin] = useState('ALL')
  const [stateVideo, setStateVideo] = useState<string>('SEGMENTED')

  const [filtersVisible, setFiltersVisible] = useState(true)
  const [segmentIdToSuggestion, setsegmentToSuggestion] = useState<ISegmentViewProcessVideo | null>(null)
  const [isSegmenting, setIsSegmenting] = useState(false)
  const [searchSign, setSearchSign] = useState('')
  const [selectedVideo, setSelectedVideo] = useState<IVideoWithId | null>(null)
  const [editing, setEditing] = useState(false)
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)

  const [categoriesToSearch, setCategoriesToSearch] = useState<IOptionsSelect[]>([])
  const [sentencesOriginToSearch, setSentencesOriginToSearch] = useState<IOptionsSelect[]>([])
  const [signsData, setSignsData] = useState<Record<string, ISignWithRef | null>>({})

  const [origins, setOrigins] = useState<string[]>([])

  const fsUser = useFsUserDocData()

  const initialVideoQuery = query(
    getCollectionReference(fsUser.workspace, 'videos') as Query<DocumentData>,
    where('_state', '==', stateVideo),
    orderBy('searchTerms'),
    orderBy('segmentsToProcess'),
    limit(1),
  )
  const [videoQueryState, setVideoQueryState] = useState<Query<DocumentData>>(initialVideoQuery)

  const videosCollection = useFirestoreCollection(videoQueryState)

  const showSnackbar = useSnackbar()

  const [hasMoreResults, setHasMoreResults] = useState(false)
  const [paginationConfig, setPaginationConfig] = useState<IPaginationConfig>(initialPaginationConfig)
  const [videos, setVideos] = useState<IVideoWithId[]>([])
  const [segments, setSegments] = useState<ISegmentViewProcessVideo[]>([])
  const [opennedConfirmSaveDialog, setOpennedConfirmSaveDialog] = useState(false)
  const [opennedConfirmCancelDialog, setOpennedConfirmCancelDialog] = useState(false)
  const [opennedBackVideoStateDialog, setOpennedBackVideoStateDialog] = useState(false)
  const [opennedEditSegmentationDialog, setOpennedEditSegmentationDialog] = useState(false)
  const [opennedDeleteVideoDialog, setOpennedDeleteVideoDialog] = useState(false)
  const [opennedBackToRecordingDialog, setOpennedBackToRecordingDialog] = useState(false)
  const [opennedDeprocessWarningDialog, setOpennedDeprocessWarningDialog] = useState(false)
  const [opennedAfterBackVideoState, setOpennedAfterBackVideoState] = useState(false)

  const [signOnDemandLinked, setSignOnDemandLinked] = useState<Record<string, ISignOnDemand>>({})
  const [playgroundMode, setPlaygroundMode] = useState(false)

  const [users, setUsers] = useState<Record<string, IUserInfo>>({})

  const [stateBackVideoState, setStateBackVideoState] = useState<'RECORDED' | 'DELETED' | null>(null)

  const videoId = window.location.hash && window.location.hash.replace('#', '')

  const fsUserRef = useFsUserDocRef()
  const intl = useIntl()
  const database = useDatabase()
  const classes = useStyles()

  const [requestedSigns, setRequestedSigns] = useState<Record<string, string[]>>({})

  const optionsStateVideo: IOptionsSelect[] = [
    { label: intl.get('pages.processVideo.none'), value: 'NONE' },
    { label: intl.get('pages.processVideo.validated'), value: 'VALIDATED' },
    { label: intl.get('pages.processVideo.processed'), value: 'PROCESSED' },
    { label: intl.get('pages.processVideo.segmented'), value: 'SEGMENTED' },
    {
      label: intl.get('pages.processVideo.noSegmented'),
      value: 'EXTRACTED_FRAMES',
    },
    {
      label: intl.get('messages.missingSign'),
      value: 'MISSING_SIGN',
    },
    {
      label: intl.get('messages.quarantine'),
      value: 'QUARANTINE',
    },
  ]

  useEffect(() => {
    const _videos: IVideoWithId[] = []

    const _paginationConfig = { ...paginationConfig }
    _paginationConfig.lastOfList = videosCollection.data.docs[videosCollection.data.docs.length - 1]
    _paginationConfig.firstOfList = videosCollection.data.docs[0]
    _paginationConfig.load = false

    for (const videoSnapshot of videosCollection.data.docs) {
      const videoData = videoSnapshot.data() as IVideoWithId
      videoData.id = videoSnapshot.id
      videoData.ref = videoSnapshot.ref
      _videos.push(videoData)
    }
    setPaginationConfig(_paginationConfig)
    setVideos(_videos)
    setHasMoreResults(paginationConfig.rowsPerPage === _videos.length)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [videosCollection.data])

  const toggleConfirmSaveDialog = () => {
    setOpennedConfirmSaveDialog(!opennedConfirmSaveDialog)
  }

  const toggleConfirmCancelDialog = () => {
    setOpennedConfirmCancelDialog(!opennedConfirmCancelDialog)
  }

  const toggleBackVideoStateDialog = () => {
    setOpennedBackVideoStateDialog(!opennedBackVideoStateDialog)
  }

  const toggleEditSegmentationDialog = () => {
    setOpennedEditSegmentationDialog(!opennedEditSegmentationDialog)
  }

  const toggleDeleteVideoDialog = () => {
    setOpennedDeleteVideoDialog(!opennedDeleteVideoDialog)
  }

  const toggleOpenningAfterBackVideoState = () => {
    setOpennedAfterBackVideoState(!opennedAfterBackVideoState)
  }

  const toggleDeprocessWarningDialog = () => {
    setOpennedDeprocessWarningDialog(!opennedDeprocessWarningDialog)
  }

  const handleCleanSelectedVideo = () => {
    window.history.pushState(null, '', window.location.pathname)
    setEditing(false)
    setSelectedVideo(null)
    setSegments([])
    setSearchSign('')
    setPlaygroundMode(false)
    setSignsData({})
  }

  const handleSearchVideo = () => {
    handleCleanSelectedVideo()
    const _paginationConfig = { ...paginationConfig }
    _paginationConfig.firstOfList = null
    _paginationConfig.lastOfList = null
    _paginationConfig.currentPage = 0
    _paginationConfig.load = true
    setPaginationConfig(_paginationConfig)
  }

  const onKeyUpSearch = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.keyCode === 13) {
      handleSearchVideo()
    }
  }

  const handleTextSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const text = (event.target && event.target.value) || ''
    setSearchText(text)
  }

  const handleChangePage = useCallback(
    (_event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null, page: number) => {
      const _paginationConfig = { ...paginationConfig }
      if (page > _paginationConfig.currentPage) {
        _paginationConfig.firstOfList = null
      } else {
        _paginationConfig.lastOfList = null
      }
      _paginationConfig.currentPage = page
      _paginationConfig.load = true
      setPaginationConfig(_paginationConfig)
      window.history.pushState(null, '', window.location.pathname)
    },
    [paginationConfig],
  )

  const onChangeRowsPerPage = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const _paginationConfig = { ...paginationConfig }
      _paginationConfig.rowsPerPage = Number(event.target.value)
      _paginationConfig.currentPage = 0
      _paginationConfig.lastOfList = null
      _paginationConfig.firstOfList = null
      _paginationConfig.load = true
      setPaginationConfig(_paginationConfig)
      window.history.pushState(null, '', window.location.pathname)
    },
    [paginationConfig],
  )
  const sleep = (ms: number) => {
    return new Promise((resolve) => setTimeout(resolve, ms))
  }

  useEffect(() => {
    const call = async () => {
      //Quando alterar para false atualiza videoId
      if (!isSegmenting && !paginationConfig.load && (!segments || segments.length == 0)) {
        await sleep(1500)
        handleCleanSelectedVideo()
        // await sleep(1500)
        // handleSearchVideo()
      }
    }
    call()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSegmenting])

  useEffect(() => {
    window.onbeforeunload = (event: BeforeUnloadEvent) => {
      if (selectedVideo && !playgroundMode) {
        updateIsProcessingVideo(selectedVideo.ref, false)
      }
      event.preventDefault()
    }
    return () => {
      if (selectedVideo && !playgroundMode) {
        updateIsProcessingVideo(selectedVideo.ref, false)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedVideo])

  const getCategoriesAndSentencesOrigin = async () => {
    // Retorna ao padrão
    setSentenceCategory('ALL')
    setSentenceOrigin('ALL')

    // Resgata as categorias
    const _categoriesToSearch: IOptionsSelect[] = [{ label: intl.get('messages.all'), value: 'ALL' }]

    const _categories: string[] = intl
      .get('sentenceCategories')
      .split(',')
      .map((el) => {
        return el.trim()
      })

    if (_categories)
      _categories.forEach((el) => {
        _categoriesToSearch.push({ label: el, value: el })
      })
    setCategoriesToSearch(_categoriesToSearch)

    // Resgata as origens das sentenças
    const _sentencesOriginToSearch: IOptionsSelect[] = [{ label: intl.get('messages.all'), value: 'ALL' }]
    const sentencesOriginReferenceString = `${fsUser.workspace.id}/${fsUser.oralLanguageId}/${fsUser.signLanguageId}/sentenceOrigins`

    const sentencesOriginReference = ref(database, sentencesOriginReferenceString)
    const _sentencesOriginSnapshot = await getData(sentencesOriginReference)
    const _sentencesOrigin = _sentencesOriginSnapshot.val() as string[]
    if (_sentencesOrigin)
      _sentencesOrigin.forEach((el) => {
        _sentencesOriginToSearch.push({ label: el, value: el })
      })
    setSentencesOriginToSearch(_sentencesOriginToSearch)
  }

  const fetchVideos = useCallback(
    async (hasVideoId: boolean) => {
      try {
        let videosQuery = query(getCollectionReference(fsUser.workspace, 'videos') as Query<DocumentData>)

        if (hasVideoId && videoId) {
          const videoIdRef = getDocumentReference(fsUser.workspace, 'videos', videoId)
          const _videos: IVideoWithId[] = []
          const video = await getFirestoreDocument(videoIdRef)
          const videoData = video.data() as IVideoWithId
          videoData.id = video.id
          videoData.ref = video.ref as DocumentReference
          _videos.push(videoData)

          videosQuery = query(videosQuery, where(documentId(), '==', videoId))

          const _videosTest: IVideoWithId[] = []
          for (const videoSnapshot of videosCollection.data.docs) {
            const videoData = videoSnapshot.data() as IVideoWithId
            videoData.id = videoSnapshot.id
            videoData.ref = videoSnapshot.ref
            _videosTest.push(videoData)
          }

          if (_videos.length == 1 && _videosTest.length == 1 && _videos[0].id == _videosTest[0].id) return
          setVideos(_videos)
          setVideoQueryState(videosQuery)
        } else {
          if (stateVideo != 'NONE') {
            videosQuery = query(
              videosQuery,
              where('_state', '==', stateVideo === 'MISSING_SIGN' ? 'SEGMENTED' : stateVideo),
            )
          }

          videosQuery = query(videosQuery, where('createdOnDemand', '==', false))
          if (stateVideo === 'MISSING_SIGN' || stateVideo === 'SEGMENTED' || stateVideo === 'QUARANTINE') {
            videosQuery = query(videosQuery, where('needCreateSign', '==', stateVideo === 'MISSING_SIGN'))
          }

          if (sentenceOrigin != 'ALL') {
            videosQuery = query(videosQuery, where('sentenceOrigin', '==', sentenceOrigin.toUpperCase()))
          }
          if (sentenceCategory != 'ALL') {
            videosQuery = query(videosQuery, where('sentenceCategory', 'array-contains', sentenceCategory))
          }
          if (searchText.trim()) {
            const searchTerms = generateSearchTermsVideo(searchText, fsUser.workspace.id)
            videosQuery = query(
              videosQuery,
              where('searchTerms', '>=', searchTerms.trim()),
              where('searchTerms', '<=', searchTerms.trim() + '\uf8ff'),
            )
          }
          videosQuery = query(videosQuery, orderBy('searchTerms'), orderBy('segmentsToProcess'))

          if (paginationConfig.lastOfList || paginationConfig.firstOfList) {
            if (paginationConfig.lastOfList) {
              videosQuery = query(videosQuery, startAfter(paginationConfig.lastOfList))
            } else {
              videosQuery = query(
                videosQuery,
                endBefore(paginationConfig.firstOfList),
                limitToLast(paginationConfig.rowsPerPage),
              )
            }
          }

          if (!paginationConfig.firstOfList) videosQuery = query(videosQuery, limit(paginationConfig.rowsPerPage))

          setVideoQueryState(videosQuery)
        }
        // const videosSnapshot = await getDocs(videosQuery)

        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } catch (e: any) {
        console.log(e.message)
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      videoId,
      fsUser.workspace,
      stateVideo,
      sentenceOrigin,
      sentenceCategory,
      searchText,
      paginationConfig.lastOfList,
      paginationConfig.firstOfList,
      paginationConfig.rowsPerPage,
    ],
  )

  useEffect(() => {
    if (videoId && paginationConfig.load) {
      fetchVideos(true)
    } else if (paginationConfig.load) {
      fetchVideos(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paginationConfig])

  useEffect(() => {
    if (videoId && videos.length == 1) {
      setIsLoading(true)
      handleCleanSelectedVideo()
      const videoSelected = videos[videos.findIndex((el) => el.id == videoId)] || null
      setSelectedVideo(videoSelected)

      const sentenceOrigin = videoSelected?.sentenceOrigin
      const sentenceState = videoSelected?._state

      if (sentenceOrigin && sentenceState) {
        setSentenceOrigin(sentenceOrigin)
        setStateVideo(sentenceState)
      }
      if (videoSelected) {
        updateIsProcessingVideo(videoSelected.ref, true)
      }
    }
    //  eslint-disable-next-line react-hooks/exhaustive-deps
  }, [videos])

  const fetchUsers = async () => {
    const { data } = await getAllUsersFunction({})
    setUsers({ ...data.users })
  }

  useEffect(() => {
    fetchUsers()
  }, [])

  useEffect(() => {
    setPaginationConfig(initialPaginationConfig)
    getCategoriesAndSentencesOrigin()
    fetchRequestedSigns()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fsUser.workspace.id])

  const handleSaveSignOnDemand = async () => {
    const _signOnDemandLinked = { ...signOnDemandLinked }
    const keys = Object.keys(_signOnDemandLinked)

    await removeSignsOnDemandLinked()

    // Cria as solicitações de sinal sob demanda
    for (const key of keys) {
      if (!_signOnDemandLinked[key].id) {
        const signOnDemandRef = await addFirestoreDocument(
          getCollectionReference(fsUser.workspace, 'signsOnDemand'),
          _signOnDemandLinked[key],
        )
        _signOnDemandLinked[key].id = signOnDemandRef.id
      }
      // Cria vinculo
      if (_signOnDemandLinked[key].id) {
        await handleLinkSignOnDemandSegment(_signOnDemandLinked[key].id || '', key)
      }
    }
    await fetchRequestedSigns()
    setSignOnDemandLinked(_signOnDemandLinked)
  }

  const handleProcessVideo = async (changeStateVideo?: boolean, clearLinkedSigns?: boolean) => {
    if (!segments.length || isLoading) return
    handleSaveSignOnDemand()

    setOpennedConfirmSaveDialog(false)
    if (!changeStateVideo) setIsLoading(true)

    let _segments = [...segments]
    try {
      showSnackbar(intl.get('messages.saving'), { variant: 'info' })
      if (clearLinkedSigns) {
        _segments = _segments.map((el) => {
          return { ...el, movement: null }
        })
        setSignsData({})
        setSegments(_segments)
      }
      const processVotesVideo: IProcessVideoInput[] = []
      const segmentsWithoutSign = []
      for (const segment of _segments) {
        // Setar datilologia
        if ((segment.oldType != segment.type || segment.oldText != segment.text) && segment.ref) {
          await updateFirestoreDocument(segment.ref, {
            type: segment.type,
            text: segment.text,
          })
        }
        if (!segment.movement && segment.type != 'fingerspell') segmentsWithoutSign.push(segment.id)
        // Foi alterado o movimento
        if (segment.movement?.id != segment.oldMovement?.id) {
          if (segment.oldMovement?.id) {
            const signSnapshot = await getFirestoreDocument(segment.oldMovement as DocumentReference)
            const signData = signSnapshot.data() as ISign
            const processVideoData: IProcessVideoInput = {
              segmentId: segment.id || '',
              score: -2,
              votes: { [signData.primarySegment?.id || '']: -2 },
            }
            processVotesVideo.push(processVideoData)
          }

          // Caso tenha sido inserido movimento setar score para 2
          if (segment.movement?.id) {
            const signSnapshot = await getFirestoreDocument(segment.movement as DocumentReference)
            const signData = signSnapshot.data() as ISign
            const processVideoData: IProcessVideoInput = {
              segmentId: segment.id || '',
              score: 2,
              votes: { [signData.primarySegment?.id || '']: 2 },
            }
            processVotesVideo.push(processVideoData)
          }
        }
      }
      const videoUpdateData: Partial<IVideo> = {}
      if (selectedVideo && selectedVideo._state === 'QUARANTINE' && stateBackVideoState !== 'DELETED') {
        videoUpdateData['revision'] = {
          user: fsUserRef,
          lastUpdate: Timestamp.now(),
        }
        videoUpdateData['paralelSentenceReported'] = false
      }

      if (processVotesVideo.length) {
        await processVideoOnCallFunction({
          userId: fsUserRef.id,
          workspaceId: fsUser.workspace.id as WorkspaceId,
          processVideoData: processVotesVideo,
        })
      }

      if (selectedVideo && stateBackVideoState !== 'DELETED') {
        videoUpdateData['segmentsToProcess'] = segmentsWithoutSign.length

        if (!changeStateVideo) {
          videoUpdateData['needCreateSign'] = segmentsWithoutSign.length > 0
        }
        await updateVideo(selectedVideo?.ref, videoUpdateData)
      }
      if (!changeStateVideo) {
        handleCleanSelectedVideo()

        showSnackbar(intl.get('messages.savedSuccessfully'), {
          variant: 'success',
        })
      }
      // Atraso de 500 milisegundos para atualizar a lista
      await sleep(500)
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (e: any) {
      console.log(e.message)
      showSnackbar(intl.get('messages.failed'), { variant: 'error' })
    }
    if (!changeStateVideo) setIsLoading(false)
  }

  const existsSegmentProcessed = () => {
    return segments.findIndex((segment) => segment.movement) != -1
  }

  const handleBackVideoState = (newState: 'RECORDED' | 'DELETED' | null) => {
    setAnchorEl(null)
    setStateBackVideoState(newState)
    setOpennedBackVideoStateDialog(true)
  }

  const handleBackToRecording = async () => {
    setOpennedDeprocessWarningDialog(false)
    // Checa se existe segmento primário
    let existsLinkedToPrimary = false
    segments.forEach((el) => {
      const signData = signsData[el.id]

      if (!existsLinkedToPrimary) {
        existsLinkedToPrimary = (signData && el.id === signData.signData.primarySegment?.id) || false
      }
    })
    if (existsLinkedToPrimary) {
      showSnackbar(intl.get('pages.processVideo.linkedPrimarySegmentToBackVideoState'), { variant: 'error' })
      return
    }

    await handleProcessVideo(true, true)

    setStateBackVideoState('DELETED')
    setOpennedBackToRecordingDialog(true)
    const originsReferenceString = `${fsUser.workspace.id}/${fsUser.oralLanguageId}/${fsUser.signLanguageId}/sentenceOrigins`
    const originsRef = ref(database, originsReferenceString)
    const originsData = (await get(originsRef)).val() as IOriginData

    setOrigins(
      Object.entries(originsData)
        .map((value) => (value[0] != 'NO_ID_FIELD' ? value[1] : null))
        .filter((el) => {
          if (el) return el
        }),
    )
  }

  const saveBackVideoState = async () => {
    setOpennedBackVideoStateDialog(false)
    if (!stateBackVideoState) {
      return
    }
    // Checa se existe segmento primário
    let existsLinkedToPrimary = false
    segments.forEach((el) => {
      const signData = signsData[el.id]

      if (!existsLinkedToPrimary) {
        existsLinkedToPrimary = (signData && el.id === signData.signData.primarySegment?.id) || false
      }
    })
    if (existsLinkedToPrimary) {
      showSnackbar(intl.get('pages.processVideo.linkedPrimarySegmentToBackVideoState'), { variant: 'error' })
      return
    }

    // Desprocessa os segmentos
    await handleProcessVideo(true, true)

    setIsLoading(true)
    if (selectedVideo) {
      // Dados da nova frase cadastrada
      const newSentence: ISentenceData = {
        priority: 10,
        sentence: selectedVideo.sentence,
        sentenceOrigin: selectedVideo.sentenceOrigin?.toUpperCase() || 'INTERNAL',
        sentenceCategory: selectedVideo.sentenceCategory || ['undefined'],
        isValidated: false,
        corpusGroup: selectedVideo.corpusGroup || 'TRAIN',
        clientId: selectedVideo.clientId || 'HT',
        isRecording: false,
        needResearch: false,
        isRepeated: false,
        userRecording: '',
        isExternal: selectedVideo.isExternal || false,
      }

      const videoId = selectedVideo.ref.id

      if (stateBackVideoState !== 'RECORDED') {
        handleCleanSelectedVideo()
      }

      backVideoStateFunction({
        videoId: videoId,
        newState: stateBackVideoState,
        workspaceId: fsUser.workspace.id as WorkspaceId,
      })
        .then(
          () => {
            if (stateBackVideoState === 'DELETED') {
              setPhraseToRecordVideoPriority(database, fsUser, newSentence)
            }

            showSnackbar(
              intl.get('messages.videoReturnedToQueue', {
                queue:
                  stateBackVideoState === 'DELETED'
                    ? intl.get('messages.recordingQueue')
                    : intl.get('messages.segmentationQueue'),
              }),
              { variant: 'success' },
            )
            if (stateBackVideoState === 'RECORDED') {
              handleAfterOnBackVideoState()
            }
          },
          () => {
            showSnackbar(
              intl.get('messages.errorToReturnVideoState', {
                queue:
                  stateBackVideoState === 'DELETED'
                    ? intl.get('messages.recordingQueue')
                    : intl.get('messages.segmentationQueue'),
              }),
              { variant: 'error' },
            )
          },
        )
        .finally(() => {
          setIsLoading(false)
        })
    }
  }

  const handleAfterOnBackVideoState = () => {
    setOpennedAfterBackVideoState(true)
  }

  const handleClickDeleteVideo = async () => {
    setOpennedDeleteVideoDialog(false)

    // Checa se existe segmento primário
    let existsLinkedToPrimary = false
    segments.forEach((el) => {
      const signData = signsData[el.id]

      if (!existsLinkedToPrimary) {
        existsLinkedToPrimary = (signData && el.id === signData.signData.primarySegment?.id) || false
      }
    })
    if (existsLinkedToPrimary) {
      showSnackbar(intl.get('pages.processVideo.linkedPrimarySegmentToBackVideoState'), { variant: 'error' })
      return
    }
    // Checa se todos estão desprocessados
    await handleProcessVideo(true, true)

    setIsLoading(true)
    if (selectedVideo) {
      const videoId = selectedVideo.ref.id
      handleCleanSelectedVideo()

      backVideoStateFunction({
        videoId: videoId,
        newState: 'DELETED',
        workspaceId: fsUser.workspace.id as WorkspaceId,
      })
        .then(
          () => {
            showSnackbar(intl.get('messages.videoDeleted'), {
              variant: 'success',
            })
          },
          () => {
            showSnackbar(intl.get('messages.errorTryDeleteVideo'), {
              variant: 'error',
            })
          },
        )
        .finally(() => {
          setIsLoading(false)
          setSelectedVideo(null)
          setSelectedVideo(null)
          setSelectedVideo(null)
        })
    }
  }

  const handleClickVideoItem = (id: string) => {
    if (selectedVideo?.id === id) return
    setIsLoading(true)
    handleCleanSelectedVideo()
    const videoSelected = videos[videos.findIndex((el) => el.id == id)] || null
    setSelectedVideo(videoSelected)

    if (videoSelected) {
      window.history.pushState(null, '', `#${id}`)
      updateIsProcessingVideo(videoSelected.ref, true)
    }
  }

  const handleOpenPlaygroundMode = (id: string) => {
    if (selectedVideo?.id === id) return
    setIsLoading(true)
    handleCleanSelectedVideo()
    setPlaygroundMode(true)
    const videoSelected = videos[videos.findIndex((el) => el.id == id)] || null
    setSelectedVideo(videoSelected)
    window.history.pushState(null, '', `#${id}`)
    showSnackbar(intl.get('messages.enteringPlaygroundMode'), {
      variant: 'info',
    })
  }

  const updateIsProcessingVideo = (ref: DocumentReference, value: boolean) => {
    updateVideo(ref, {
      isProcessingVideo: {
        user: fsUserRef,
        lastUpdate: serverTimestamp() as Timestamp,
        value,
      },
    })
  }

  const getSignsOnDemandBatch = async (workspaceRef: DocumentReference, signsOnDemandIds: string[]) => {
    const result: Record<string, ISignOnDemand> = {}

    const batch: string[][] = []
    const batchNumber = 9
    for (let i = 0; i < signsOnDemandIds.length; i = i + batchNumber) {
      batch.push(signsOnDemandIds.slice(i, i + batchNumber))
    }

    for (const item of batch) {
      const signsOnDemandQuery = query(collection(workspaceRef, 'signsOnDemand'), where(documentId(), 'in', item))
      const signsOnDemandSnapshot = await getDocs(signsOnDemandQuery)

      signsOnDemandSnapshot.docs.forEach((signOnDemandSnapshot) => {
        result[signOnDemandSnapshot.id] = {
          ...signOnDemandSnapshot.data(),
          id: signOnDemandSnapshot.id,
        } as ISignOnDemand
      })
    }
    return result
  }

  const fetchRequestedSigns = async () => {
    const referenceString = `${fsUser.workspace.id}/${fsUser.oralLanguageId}/${
      fsUser.signLanguageId
    }/${'requestedSigns'}`
    try {
      const sfDocRef = ref(database, referenceString)
      const values = await getData(sfDocRef)
      setRequestedSigns(values.val() as Record<string, string[]>)
    } catch (e) {
      console.log(e)
    }
  }

  const fetchSignsOnDemand = useCallback(
    async (segmentsIds: string[]) => {
      const _requestedSigns = { ...requestedSigns }
      const _signsOnDemand: Record<string, ISignOnDemand> = {}
      const signsOnDemandIds: string[] = []
      for (const segmentId of segmentsIds) {
        const item = Object.entries(_requestedSigns).find((el) => el[1].includes(segmentId))
        if (item) {
          const signOnDemandId = item[0]
          signsOnDemandIds.push(signOnDemandId)
          // signsOnDemand.push(el[0])
        }
      }
      const signsOnDemandResult = await getSignsOnDemandBatch(fsUser.workspace as DocumentReference, signsOnDemandIds)
      for (const segmentId of segmentsIds) {
        const item = Object.entries(_requestedSigns).find((el) => el[1].includes(segmentId))
        if (item) {
          const signOnDemandId = item[0]
          _signsOnDemand[segmentId] = signsOnDemandResult[signOnDemandId]
        }
      }
      setSignOnDemandLinked(_signsOnDemand)
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [requestedSigns, segments],
  )

  const removeSignsOnDemandLinked = async () => {
    for (const segment of segments) {
      // existe nos linkados anteriores e não no novo
      for (const key of Object.keys(requestedSigns || {})) {
        if (
          segment &&
          segment.id &&
          requestedSigns[key].includes(segment.id) &&
          (!signOnDemandLinked[segment.id] || signOnDemandLinked[segment.id].id !== key)
        ) {
          const referenceString = `${fsUser.workspace.id}/${fsUser.oralLanguageId}/${
            fsUser.signLanguageId
          }/${'requestedSigns'}/${key}`
          const sfDocRef = ref(database, referenceString)
          await runTransaction(sfDocRef, (current_value: string[]) => {
            const values: string[] = current_value || []
            const index = values.findIndex((el) => el === segment.id || '')
            if (index > -1) {
              values.splice(index, 1)
            }
            return values
          })
        }
      }
    }
  }

  const handleLinkSignOnDemandSegment = async (signOnDemandId: string, segmentId: string) => {
    const referenceString = `${fsUser.workspace.id}/${fsUser.oralLanguageId}/${
      fsUser.signLanguageId
    }/${'requestedSigns'}/${signOnDemandId}`
    try {
      const sfDocRef = ref(database, referenceString)
      await runTransaction(sfDocRef, (current_value: string[]) => {
        const values: string[] = current_value || []
        if (!values.includes(segmentId)) {
          values.push(segmentId || '')
        }
        return values
      })
    } catch (e) {
      console.log('Transaction failed: ', e)
    }
  }

  // Usuário não tem permissão no modulo
  if (!hasPermissionOnModule) {
    return (
      <Layout title={intl.get('modules.processVideo')}>
        <CardMessage
          title={intl.get('components.layout.featureLocked')}
          subtitle={intl.get('components.layout.talkToAdmin')}
        />
      </Layout>
    )
  }

  const handleOpenEditSegment = async () => {
    setOpennedEditSegmentationDialog(false)
    if (editing) await handleProcessVideo(true)
    setIsSegmenting(true)
  }

  const itemsFabGroupBackVideo = [
    <Tooltip key={1} placement="left-end" title={intl.get('messages.backToRecording')}>
      <Button
        style={{ justifyContent: 'flex-start' }}
        fullWidth={true}
        onClick={() => {
          setOpennedDeprocessWarningDialog(true)
          setAnchorEl(null)
        }}
      >
        {intl.get('messages.backToRecording')}
      </Button>
    </Tooltip>,
    <Tooltip key={2} placement="left-end" title={intl.get('messages.backToSegmentation')}>
      <Button
        fullWidth={true}
        style={{ justifyContent: 'flex-start' }}
        onClick={() => {
          handleBackVideoState('RECORDED')
        }}
      >
        {intl.get('messages.backToSegmentation')}
      </Button>
    </Tooltip>,
    <Tooltip key={3} placement="left-end" title={intl.get('messages.editSegmentation')}>
      <Button
        disabled={segments.length == 0}
        fullWidth={true}
        style={{ justifyContent: 'flex-start' }}
        onClick={() => {
          setAnchorEl(null)
          toggleEditSegmentationDialog()
        }}
      >
        {intl.get('messages.editSegmentation')}
      </Button>
    </Tooltip>,
    <Tooltip key={4} placement="left-end" title={intl.get('messages.deleteVideo')}>
      <Button
        style={{ justifyContent: 'flex-start' }}
        fullWidth={true}
        onClick={() => {
          setAnchorEl(null)
          toggleDeleteVideoDialog()
        }}
      >
        {intl.get('messages.deleteVideo')}
      </Button>
    </Tooltip>,
  ]

  return (
    <>
      {!isSegmenting && (
        <Layout title={intl.get('modules.processVideo')} requiredModule={'processVideo'}>
          <Grid item md={12} container>
            <Grid
              item
              md={4}
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'flex-start',
                pointerEvents: editing ? 'none' : 'auto',
              }}
            >
              <Grid item md={11} container>
                <FormControlLabel
                  control={
                    <Switch
                      classes={{
                        track: classes.switch_track,
                        switchBase: classes.switch_base,
                        colorPrimary: classes.switch_primary,
                      }}
                      checked={filtersVisible}
                      onChange={() => {
                        setFiltersVisible(!filtersVisible)
                      }}
                    />
                  }
                  label={intl.get('messages.showFilters')}
                />

                <Fade style={{ height: filtersVisible ? 'auto' : '0' }} in={filtersVisible}>
                  <Grid
                    item
                    container
                    style={{
                      margin: '10px 0',
                      alignItems: 'center',
                      display: 'flex',
                      flexDirection: 'row',
                      gap: '10px',
                    }}
                  >
                    <Paper variant="outlined" style={{ width: '100%' }}>
                      <Box display="flex" alignItems="center" borderBottom="1px solid #cecece" borderRadius={0}>
                        <InputBase
                          placeholder={intl.get('messages.search')}
                          style={{ padding: 10, width: '100%' }}
                          value={searchText}
                          onChange={handleTextSearchChange}
                          onKeyUp={onKeyUpSearch}
                          disabled={isLoading}
                        />

                        <IconButton
                          aria-label="search"
                          style={{ padding: 10 }}
                          onClick={() => {
                            handleSearchVideo()
                          }}
                        >
                          <SearchIcon />
                        </IconButton>
                      </Box>
                    </Paper>
                    <Box
                      style={{
                        display: 'flex',
                        width: '100%',
                        justifyContent: 'space-between',
                      }}
                    >
                      <Box width={120}>
                        <Select
                          id="category-select"
                          label={intl.get('messages.category')}
                          value={sentenceCategory}
                          disabled={isLoading}
                          onChange={(
                            event: React.ChangeEvent<{
                              name?: string | undefined
                              value: unknown
                            }>,
                          ) => {
                            setSentenceCategory(event.target.value as string)
                          }}
                          optionsValues={categoriesToSearch}
                        />
                      </Box>
                      <Box width={120}>
                        <Select
                          id="sentenceOrigin-select"
                          label={intl.get('messages.origin')}
                          value={sentenceOrigin}
                          disabled={isLoading}
                          onChange={(
                            event: React.ChangeEvent<{
                              name?: string | undefined
                              value: unknown
                            }>,
                          ) => {
                            setSentenceOrigin((event.target.value as string).toUpperCase())
                          }}
                          optionsValues={sentencesOriginToSearch}
                        />
                      </Box>

                      <Box width={120}>
                        <Select
                          id="stateVideo-select"
                          label={intl.get('messages.videoState')}
                          value={stateVideo}
                          disabled={isLoading}
                          onChange={(
                            event: React.ChangeEvent<{
                              name?: string | undefined
                              value: unknown
                            }>,
                          ) => {
                            setStateVideo(event.target.value as string)
                          }}
                          optionsValues={optionsStateVideo}
                        />
                      </Box>
                    </Box>
                  </Grid>
                </Fade>
                <Grid item md={12} style={{ maxHeight: '91vh', overflow: 'auto' }}>
                  <TableContainer style={{ height: '60vh' }} component={Paper}>
                    <Table aria-label="frases list">
                      <TableHead style={{ background: '#222' }}>
                        <TableRow>
                          <TableCell>
                            <Typography style={{ fontWeight: 'bold' }}>
                              {intl.get('pages.segmentVideo.video.sentence')}
                            </Typography>
                          </TableCell>
                          <TableCell
                            style={{
                              textAlign: 'center',
                            }}
                          >
                            <Typography
                              style={{
                                fontWeight: 'bold',
                              }}
                            >
                              {intl.get('messages.segmentsToProcess')}
                            </Typography>
                          </TableCell>
                          <TableCell
                            style={{
                              textAlign: 'center',
                            }}
                          >
                            <Typography
                              style={{
                                fontWeight: 'bold',
                              }}
                            >
                              {intl.get('messages.videoManager')}
                            </Typography>
                          </TableCell>
                        </TableRow>
                      </TableHead>

                      <TableBody>
                        {videos.map((row) => (
                          <Tooltip
                            key={row.id}
                            title={
                              row.isProcessingVideo?.value && users[row.isProcessingVideo.user.id]
                                ? users[row.isProcessingVideo.user.id].displayName || ''
                                : ''
                            }
                          >
                            <SignRow
                              selectedVideo={row?.id === selectedVideo?.id}
                              onClick={() => {
                                if (
                                  !row.isProcessingVideo?.value ||
                                  (row.isProcessingVideo && row.isProcessingVideo.user.id == fsUserRef.id)
                                ) {
                                  handleClickVideoItem(row?.id || '')
                                } else {
                                  handleOpenPlaygroundMode(row?.id || '')
                                }
                              }}
                            >
                              <SignCell
                                selectedVideo={row?.id === selectedVideo?.id}
                                isProcessingVideo={row?.isProcessingVideo?.value}
                              >
                                {row.sentence || ''}
                              </SignCell>
                              <SignCell
                                style={{
                                  textAlign: 'center',
                                }}
                                selectedVideo={row?.id === selectedVideo?.id}
                                isProcessingVideo={row?.isProcessingVideo?.value}
                              >
                                {row.segmentsToProcess || ''}
                              </SignCell>
                              <SignCell
                                style={{
                                  textAlign: 'center',
                                }}
                                selectedVideo={row?.id === selectedVideo?.id}
                                isProcessingVideo={row?.isProcessingVideo?.value}
                              >
                                <IconButton
                                  disabled={row?.id !== selectedVideo?.id}
                                  style={{
                                    padding: '5px',
                                  }}
                                  onClick={() => {
                                    const url = routes.videoManagerVideo.replace(':videoId', row?.id)
                                    window.open(url, '_blank')?.focus()
                                  }}
                                >
                                  <LaunchIcon fontSize="small" />
                                </IconButton>
                              </SignCell>
                            </SignRow>
                          </Tooltip>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                  <TablePagination
                    style={{ pointerEvents: 'auto' }}
                    component="div"
                    count={-1}
                    rowsPerPage={paginationConfig.rowsPerPage}
                    page={paginationConfig.currentPage}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={onChangeRowsPerPage}
                    rowsPerPageOptions={[10, 20, 30]}
                    labelRowsPerPage={intl.get('messages.labelRows')}
                    labelDisplayedRows={({ from, to }) =>
                      intl.get('pages.animation.labelDisplayedRows', {
                        from,
                        to,
                      })
                    }
                    nextIconButtonProps={{ disabled: !hasMoreResults }}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item md={5}>
              <Grid item md={12} container>
                {isLoading && (
                  <Grid
                    item
                    md={12}
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      height: '90vh',
                    }}
                    container
                  >
                    {' '}
                    <CircularProgress size={100} variant="indeterminate" />{' '}
                  </Grid>
                )}
                <VerticalTimeline
                  signsData={signsData}
                  setSignsData={setSignsData}
                  segments={segments}
                  setSegments={setSegments}
                  isLoading={isLoading}
                  setIsLoading={setIsLoading}
                  videoData={selectedVideo}
                  setsegmentToSuggestion={setsegmentToSuggestion}
                  setEditing={setEditing}
                  setSignOnDemandLinked={setSignOnDemandLinked}
                  signOnDemandLinked={signOnDemandLinked}
                  fetchSignsOnDemand={fetchSignsOnDemand}
                />
                {segments.length == 0 && selectedVideo && !isLoading && (
                  <Grid
                    item
                    md={12}
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      height: '90vh',
                    }}
                    container
                  >
                    <Typography>
                      {intl.get('messages.videoNotSegmentedClick')}{' '}
                      <Link
                        style={{ cursor: 'pointer' }}
                        onClick={() => {
                          setIsSegmenting(true)
                        }}
                      >
                        {intl.get('messages.here')}
                      </Link>{' '}
                      {intl.get('messages.forSegment')}
                    </Typography>
                  </Grid>
                )}
              </Grid>
            </Grid>
            <Grid item md={3} style={{ display: 'flex', justifyContent: 'center' }}>
              <Grid
                item
                style={{
                  display: 'flex',
                  flex: 1,
                  gap: '10px',
                  margin: '10px',
                  flexDirection: 'column',
                }}
                md={11}
              >
                <Grid item style={{ flex: 1, minHeight: '300px' }}>
                  <SignSearch
                    setsegmentToSuggestion={setsegmentToSuggestion}
                    segmentIdToSuggestion={segmentIdToSuggestion}
                    search={searchSign}
                    setSearch={setSearchSign}
                  />
                </Grid>
                <Grid
                  item
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-around',
                  }}
                >
                  <FabGroupButton
                    disabled={isLoading || selectedVideo == null || playgroundMode}
                    anchorEl={anchorEl}
                    setAnchorEl={setAnchorEl}
                    Elements={itemsFabGroupBackVideo}
                    Icon={UndoIcon}
                  />
                  <Button
                    disabled={isLoading || selectedVideo == null || playgroundMode}
                    variant="outlined"
                    onClick={() => toggleConfirmCancelDialog()}
                    startIcon={<CancelIcon />}
                  >
                    {intl.get('messages.cancel')}
                  </Button>
                  <Button
                    onClick={() => toggleConfirmSaveDialog()}
                    variant="contained"
                    style={{
                      background:
                        isLoading || !segments.length || opennedConfirmSaveDialog || playgroundMode ? '' : green[700],
                      color: isLoading || !segments.length || opennedConfirmSaveDialog || playgroundMode ? '' : '#fff',
                    }}
                    disabled={isLoading || !segments.length || opennedConfirmSaveDialog || playgroundMode}
                    startIcon={<SaveIcon />}
                  >
                    {intl.get('messages.save')}
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <ConfirmationDialog
            open={opennedConfirmSaveDialog}
            toggleOpen={toggleConfirmSaveDialog}
            handleYes={() => {
              handleProcessVideo()
            }}
            title={intl.get('messages.save')}
            subtitle={intl.get('messages.confirmAndSaveData')}
          />
          <ConfirmationDialog
            open={opennedConfirmCancelDialog}
            toggleOpen={toggleConfirmCancelDialog}
            handleYes={() => {
              toggleConfirmCancelDialog()
              handleCleanSelectedVideo()
            }}
            title={intl.get('messages.cancel')}
            subtitle={intl.get('messages.confirmTheCancellation')}
          />
          <ConfirmationDialog
            open={opennedBackVideoStateDialog}
            toggleOpen={toggleBackVideoStateDialog}
            handleYes={() => {
              saveBackVideoState()
            }}
            title={intl.get('messages.backVideoStatus')}
            subtitle={intl.get(
              existsSegmentProcessed() ? 'messages.wantReturnVideoWithLinkedSigns' : 'messages.wantReturnVideo',
              {
                queue:
                  stateBackVideoState === 'DELETED'
                    ? intl.get('messages.recordingQueue')
                    : intl.get('messages.segmentationQueue'),
              },
            )}
          />
          <ConfirmationDialog
            open={opennedDeprocessWarningDialog}
            toggleOpen={toggleDeprocessWarningDialog}
            handleYes={() => {
              handleBackToRecording()
            }}
            title={intl.get('messages.backVideoStatus')}
            subtitle={intl.get(
              existsSegmentProcessed() ? 'pages.processVideo.deprocessWarning' : 'messages.wantReturnVideo',
              {
                queue: intl.get('messages.recordingQueue'),
              },
            )}
          />
          <ModalBackPhraseToRecording
            handleCloseModal={() => {
              setOpennedBackToRecordingDialog(false)
            }}
            database={database}
            fsUser={fsUser}
            origins={origins}
            selectedVideo={selectedVideo}
            open={opennedBackToRecordingDialog}
          />
          <ConfirmationDialog
            open={opennedEditSegmentationDialog}
            toggleOpen={toggleEditSegmentationDialog}
            handleYes={() => {
              handleOpenEditSegment()
            }}
            title={intl.get('messages.editSegmentation')}
            subtitle={intl.get('messages.changesWillBeSavedBeforeProceeding')}
          />
          <ConfirmationDialog
            open={opennedDeleteVideoDialog}
            toggleOpen={toggleDeleteVideoDialog}
            handleYes={() => {
              handleClickDeleteVideo()
            }}
            title={intl.get('messages.deleteVideo')}
            subtitle={intl.get(
              existsSegmentProcessed()
                ? 'pages.processVideo.confirmVideoDeleteWithLinkedSigns'
                : 'pages.processVideo.confirmVideoDelete',
            )}
          />

          <ConfirmationDialog
            open={opennedAfterBackVideoState}
            toggleOpen={toggleOpenningAfterBackVideoState}
            handleYes={() => {
              if (stateBackVideoState === 'RECORDED') {
                setSegments([])
                setIsSegmenting(true)
              }
              setOpennedAfterBackVideoState(false)
            }}
            subtitle={intl.get('pages.processVideo.wouldYouLikeToDoTheSegmentation')}
            title={''}
          />
        </Layout>
      )}
      {isSegmenting && selectedVideo?.id && (
        <SegmentVideo
          videoIdParam={selectedVideo?.id || ''}
          userIdParam={segments && segments.length > 0 ? segments[0].createdBy.id : null}
          setIsSegmenting={setIsSegmenting}
        />
      )}
    </>
  )
}

export default ProcessVideo
