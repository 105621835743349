import firebase from 'firebase/compat/app'
import '@firebase/auth'
import 'firebase/analytics'
import 'firebase/database'
import '@firebase/storage'
import '@firebase/firestore'
import 'firebase/functions'
import 'firebase/performance'
import '@firebase/remote-config'
import { IFunctions, IFunctionDefinition } from 'collections'
import { httpsCallable, getFunctions } from '@firebase/functions'
import { ReCaptchaV3Provider, initializeAppCheck } from 'firebase/app-check'
import { getAuth } from 'firebase/auth'
// TODO mover par aconfiguração automática
/**
 * Configuração padrão do firebase
 */

export const fbConfig = {
  apiKey: process.env.REACT_APP_DEVELOPMENT_KEY || 'AIzaSyBU_nW5H6DbCIE2sMon-r2VZ_aeRPYbEeE',
  authDomain: 'community.handtalk.me',
  databaseURL: `https://${
    window.location.toString().includes('/motion') ? 'ht-motion' : 'ht-community'
  }.firebaseio.com`,
  projectId: 'ht-community',
  storageBucket: 'ht-community-2',
  messagingSenderId: '436911997852',
  appId: '1:436911997852:web:759451fbca5c3592',
  measurementId: 'G-9LB9767MRS',
}

export const fbApp = firebase.initializeApp(fbConfig)
export const auth = getAuth(fbApp)
if (window.location.hostname == 'localhost') {
  // @ts-ignore
  self.FIREBASE_APPCHECK_DEBUG_TOKEN = true
}
export const appCheck = initializeAppCheck(fbApp, {
  provider: new ReCaptchaV3Provider('6Ld-zmQpAAAAAOgawnWVMpHOESNcUq2Q7z3IkWop'),

  // Optional argument. If true, the SDK automatically refreshes App Check
  // tokens as needed.
  isTokenAutoRefreshEnabled: true,
})

const functions = getFunctions()
const getFunction =
  <T extends IFunctionDefinition>(functionName: keyof IFunctions) =>
  async (input: T['input']) => {
    const { data } = await httpsCallable(functions, functionName)(input)
    return { data } as { data: T['output'] }
  }

// Importante! Obedeça o padrão do nome da função no front end e no back end.
// A função do front é o nome da função do back + "Function"
export const feedbackFunction = getFunction<IFunctions['feedback']>('feedback')

export const getNextSentenceFromDatabaseFunction =
  getFunction<IFunctions['getNextSentenceFromDatabase']>('getNextSentenceFromDatabase')

export const getNextVideosToTagFunction = getFunction<IFunctions['getNextVideosToTag']>('getNextVideosToTag')

export const setUserNameFunction = getFunction<IFunctions['setUserName']>('setUserName')

export const checkUserRole = getFunction<IFunctions['checkUserRole']>('checkUserRole')

export const sendMailOnCallFunction = getFunction<IFunctions['sendMailOnCall']>('sendMailOnCall')
export const skipVideoForTagFunction = getFunction<IFunctions['skipVideoForTag']>('skipVideoForTag')

export const getUserByIdFunction = getFunction<IFunctions['getUserById']>('getUserById')

export const getUsersInBatchByIdFunction = getFunction<IFunctions['getUsersInBatchByIds']>('getUsersInBatchByIds')

export const getUsersByWorkspaceFunction = getFunction<IFunctions['getUsersByWorkspace']>('getUsersByWorkspace')

export const getAllUsersFunction = getFunction<IFunctions['getAllUsers']>('getAllUsers')

export const addContribSegmentFunction = getFunction<IFunctions['addContribSegment']>('addContribSegment')

export const backVideoStateFunction = getFunction<IFunctions['backVideoStateOnCall']>('backVideoStateOnCall')

export const deleteSignOnDemandFunction =
  getFunction<IFunctions['deleteSignOnDemandOnCall']>('deleteSignOnDemandOnCall')

export const addContribFindIdenticalSegmentsFunction = getFunction<IFunctions['addContribFindIdenticalSegments']>(
  'addContribFindIdenticalSegments',
)

export const addContribAnimationReviewFunction =
  getFunction<IFunctions['addContribAnimationReview']>('addContribAnimationReview')

export const createFirebaseUserFunction = getFunction<IFunctions['createFirebaseUser']>('createFirebaseUser')

export const updateQueuePriorityFunction = getFunction<IFunctions['updatePriority']>('updatePriority')

export const updateMatchSegmentsPriorityFunction =
  getFunction<IFunctions['updateMatchSegmentsPriority']>('updateMatchSegmentsPriority')

export const changePrimarySegmentFunction = getFunction<IFunctions['changePrimarySegment']>('changePrimarySegment')

export const removeSegmentFromClusterFunction =
  getFunction<IFunctions['removeSegmentFromCluster']>('removeSegmentFromCluster')

export const joinClustersFunction = getFunction<IFunctions['joinClusters']>('joinClusters')

export const promoteClusterToSignFunction = getFunction<IFunctions['promoteClusterToSign']>('promoteClusterToSign')

export const listClusterEdgesFunction = getFunction<IFunctions['listClusterEdges']>('listClusterEdges')

export const separateClusterFunction = getFunction<IFunctions['separateCluster']>('separateCluster')

export const acceptLGPDTermsFunction = getFunction<IFunctions['acceptLGPDTerms']>('acceptLGPDTerms')

export const processVideoOnCallFunction = getFunction<IFunctions['processVideoOnCall']>('processVideoOnCall')

export const getWorkspaceToUserFunction = getFunction<IFunctions['getWorkspaceToUser']>('getWorkspaceToUser')

export const createUserOnWorkspaceFunction = getFunction<IFunctions['createUserOnWorkspace']>('createUserOnWorkspace')

export default firebase
