import { useDatabase, useDatabaseObjectData, useStorage, useUser } from 'reactfire'
import { useGlobal } from 'reactn'
import React, { useState, useEffect, useCallback } from 'react'
import styled from 'styled-components'

import Grid from '@material-ui/core/Grid'

import VideoCard from '../VideoCard'
import Preloader from '../../../components/Preloader'
import Layout from '../../../components/Layout'
import CardMessage from '../../../components/CardMessage'
import { IVideo, IUserInfo, VideoId, UserId, IGlobalAttr, getPathVideoFile } from 'collections'
import { getUserByIdFunction } from '../../../services/firebase'
import { routes } from '../../../community'
import { useRoute } from 'wouter'
import Typography from '@material-ui/core/Typography'
import { useFsUserDocData } from '../../../hooks/useFsUser'
import { doc } from '@firebase/firestore'
import { useFirestore } from 'reactfire'
import useIntl from '../../../hooks/useIntl'
import useLockModules from '../../../hooks/useLockModules'
import LockModuleDialog from '../../../components/LockModuleDialog'
import { getDownloadURL, ref } from '@firebase/storage'
import { ref as dbRef } from 'firebase/database'
import { getDocumentReference } from 'collections'
import useCheckHasPermission from '../../../services/hooks/useCheckHasPermission'
import SegmentVideo from '../../SegmentVideo/Video'
import { useFirestoreData } from '../../../hooks/useFirestoreData'

const Container = styled.div`
  min-width: 600px;
  width: 40%;
  margin: 0 auto;
  margin-top: 30px;

  @media (max-width: 600px) {
    min-width: unset;
    width: 100%;
    margin-top: unset;
  }
`

const BlockGrid = styled(Grid)`
  display: flex;
  height: 300px;
  align-items: center;
  justify-content: center;
`

interface IVideoProps {
  path?: string
}
/**
 * Página de renderização de um único vídeo, recebe o id do vídeo através da url e rederiza os dados
 */
const Video: React.FC<IVideoProps> = () => {
  const [isLoading, setIsLoading] = useState(true)
  const [userInfo, setUserInfo] = useState<IUserInfo>()
  const [videoSrc, setVideoSrc] = useState<string>()
  const [origins, setOrigins] = useState<string[]>([])
  const [categories, setCategories] = useState<string[]>([])
  const [, setFsVideoRef] = useGlobal<IGlobalAttr, 'fsVideoRef'>('fsVideoRef')
  const [isSegmenting, setIsSegmenting] = useState(false)
  const [videoToEditSegment, setVideoToEditSegment] = useState({
    videoId: '',
    userId: '',
  })
  const [isMaintainer, setIsMaintener] = useState(false)
  const fbUser = useUser<firebase.default.User>()
  const intl = useIntl()
  const database = useDatabase()

  const storage = useStorage()

  const firestore = useFirestore()
  const fsUser = useFsUserDocData()

  const hasPermissionOnModule = useCheckHasPermission('videoManager')
  const { open, toggleOpen } = useLockModules()
  const [, params] = useRoute(routes.videoManagerVideo)

  const originsReferenceString = `${fsUser.workspace.id}/${fsUser.oralLanguageId}/${fsUser.signLanguageId}/sentenceOrigins`
  const originsRef = dbRef(database, originsReferenceString)
  const { status: statusOrigins, data: originsData } = useDatabaseObjectData(originsRef)

  const videoRef = doc(firestore, 'workspaces', fsUser.workspace.id, 'videos', params!.videoId)

  const videoData = useFirestoreData(videoRef.path).data as IVideo

  const getOrigins = async () => {
    if (statusOrigins == 'error') return
    if (!originsData) return
    const originsObj = originsData as { id: number; origin: string }
    const origins: string[] = []
    const originsArray = Object.entries(originsObj)
    originsArray.map((value, index) => {
      if (originsArray.length - 1 == index) return
      const origin = value as string[]
      origins.push(origin[1])
    })
    setOrigins(origins)
  }

  const getCategories = async () => {
    const _categories: string[] = intl
      .get('sentenceCategories')
      .split(',')
      .map((el) => {
        return el.trim()
      })

    setCategories(_categories)
  }

  /**
   * Pega a url de download de um video
   * @param videoData Dados do vídeo
   * @param id Id do vídeo
   */
  const getVideoDownloadUrl = useCallback(
    async (videoData: IVideo, id: VideoId) => {
      let url = ''
      try {
        // Pega a url publica de download do video
        const urlString = getPathVideoFile(fsUser.workspace.id, id)
        url = await getDownloadURL(ref(storage, urlString))

        return url
      } catch (err) {
        url = ''
      }
      return url
    },
    [fsUser.workspace.id, storage],
  )

  /**
   * Pega os dados de um determinado usuario
   * @id id do usuário que iremos consultar
   */
  const getUserInfo = useCallback(async (id: UserId) => {
    const { data } = await getUserByIdFunction({
      userId: id,
    })
    return data.user
  }, [])

  useEffect(() => {
    if (!params?.videoId) return
    const call = async () => {
      const videoRef = getDocumentReference(fsUser.workspace, 'videos', params.videoId)
      setFsVideoRef(videoRef)

      if (!videoData) {
        setIsLoading(false)
        return
      }
      const videoUrl = await getVideoDownloadUrl(videoData, params.videoId)
      const user = await getUserInfo(videoData?.createdBy.id)
      setVideoSrc(videoUrl)
      setUserInfo(user)
      setIsLoading(false)
    }
    call()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    getOrigins()
    getCategories()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    const call = async () => {
      const tokenResult = await fbUser.data?.getIdTokenResult()
      const _isMaintainer: boolean = !!tokenResult?.claims.isMaintainer

      setIsMaintener(_isMaintainer)
    }
    call()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // Usuário não tem permissão no modulo
  if (!hasPermissionOnModule) {
    return (
      <Layout title={`${intl.get('modules.signSentence')} | Video`}>
        <CardMessage
          title={intl.get('components.layout.featureLocked')}
          subtitle={intl.get('components.layout.talkToAdmin')}
        />
      </Layout>
    )
  }

  if (!params?.videoId) {
    return (
      <Layout title={`${intl.get('modules.signSentence')} | Video`}>
        <CardMessage
          title={intl.get('pages.signSentence.video.cardMessage.title')}
          subtitle={intl.get('pages.signSentence.video.cardMessage.subtitle')}
        />
      </Layout>
    )
  }

  return (
    <>
      {!isSegmenting && (
        <Layout title={`${intl.get('modules.signSentence')} | Video`} imageBg>
          <Container>
            {!isLoading ? (
              <Grid style={{ padding: 20 }}>
                {/** Se todos os dados forem encontrados exibimos o vídeo */}
                {videoData && userInfo?.displayName && params?.videoId ? (
                  <Grid item sm={12} lg={12}>
                    <VideoCard
                      _state={videoData._state}
                      clientId={videoData.clientId}
                      corpusGroup={videoData.corpusGroup}
                      createdBy={userInfo.displayName}
                      numberOfSegmentations={videoData.numberOfSegmentations}
                      numberOfSegments={videoData.numberOfSegments}
                      segmentedBy={videoData.segmentedBy}
                      errors={videoData.errors}
                      needCreateSign={videoData.needCreateSign}
                      segmentsToProcess={videoData.segmentsToProcess}
                      sentenceCategory={videoData.sentenceCategory}
                      sentenceOrigin={videoData.sentenceOrigin}
                      origins={origins}
                      categories={categories}
                      createdOnDemand={videoData.createdOnDemand}
                      signs={videoData.signs}
                      displayName={userInfo.displayName}
                      sentence={videoData.sentence}
                      videoSrc={videoSrc}
                      createdAt={videoData.createdAt.toDate()}
                      videoId={params.videoId}
                      usedOnTrain={videoData.usedOnTrain}
                      isMaintainer={isMaintainer}
                      handleOpenEditSegment={(videoId, userId) => {
                        setIsSegmenting(true)
                        setVideoToEditSegment({
                          videoId: videoId,
                          userId: userId,
                        })
                      }}
                    />
                  </Grid>
                ) : (
                  <>
                    {/** Caso contrario exibimos uma mensagem de erro */}
                    <BlockGrid item sm={12} lg={12}>
                      <Typography>{intl.get('pages.signSentence.video.notFound')}</Typography>
                    </BlockGrid>
                  </>
                )}
              </Grid>
            ) : (
              <BlockGrid item sm={12} lg={12}>
                <Preloader asBlock text={intl.get('messages.loadingVideo')} />
              </BlockGrid>
            )}
          </Container>
          <LockModuleDialog open={open} toggleOpen={toggleOpen} />
        </Layout>
      )}
      {isSegmenting && (
        <SegmentVideo
          videoIdParam={videoToEditSegment.videoId}
          userIdParam={videoToEditSegment.userId}
          setIsSegmenting={setIsSegmenting}
        />
      )}
    </>
  )
}

export default Video
